
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AwsAmplifyService } from '../shared/services/aws/awsAmplify.service';
import { CloudService } from '../shared/services/cloud.service';
import { errorMessages } from '../shared/helpers/errorMessages';
import { ApiprocedureService } from '../shared/java/apiprocedure.service';
export interface ChallengeParameters {
    CODE_DELIVERY_DELIVERY_MEDIUM: string;

    CODE_DELIVERY_DESTINATION: string;
}
@Injectable()
export class UserManagementService {
    public userLoginResponse: any;
    public userloginErrRes: any;
    constructor(
        private router: Router,
        private cloudService: CloudService,
        private aws: AwsAmplifyService,
        private logService: ApiprocedureService
    ) {

    }
    public userlogin(userName: string, password: string, callback: any): any {
        this.cloudService.login(userName, password, async (err: any, result: any) => {
            if (err) {
                this.userloginErrRes = err;
                return callback(err, null);
            }
            if (result) {
                this.userLoginResponse = result
                return callback(null, result);
            }
        });
    }

    public fetchTokenCode(code: string, vId, baseState, callback: any): any {
        this.logService.fetchTokenCode(code, vId, baseState).subscribe(
            (response) => {
                return callback(null, response);
            },
            (error) => {
                return callback(error, null);
            }
        );
    }

    public getCognitoId(callback) {
        this.aws.getIdToken()
            .subscribe(
                (response) => {
                    return callback(null, response);
                },
                (errorCognitoId) => {
                    if (errorCognitoId !== null && errorMessages[errorCognitoId.code]) {
                        errorCognitoId.message = errorMessages[errorCognitoId.code];
                    }
                    return callback(errorCognitoId, null);
                }
            );
    }


}
