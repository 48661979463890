import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiprocedureService } from '../shared/java/apiprocedure.service';
import { AuthService } from '../shared/services/auth.service';
import { SharedStateService } from '../shared/services/shared-state.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileDashboardService {
  

  constructor(
    private http: HttpClient,
    private apiProcedureService: ApiprocedureService,
    private sharedState: SharedStateService,
    private authService: AuthService
  ) { }

  /**
 * (description): to recall an upload 
 * (parameter) : uploadid, bacthid
 * (returns) : callback
 * (memberof) : FileDashboardService
 **/
  public recallUploadAPI(params): Observable<any> {
    return this.apiProcedureService.recallUploadAPI(params);
  }

    /**
 * (description): To post the fileDashboard 
 * (returns) : this will call the user list api to post the filedashboard
 * (memberof) : FileDashboardService
 **/
  public postFileDashboard(params): Observable<any> {
    return this.apiProcedureService.postFileDashboard(params);
  }

  /**
  * (description): To send details of read unread errors in dashboard
  * (parameter) : uploadId, isRead
  * (returns) : callback
  * (memberof) : FileDashboardService
  **/
  public acknowledgeErrorDetails(params): Observable<any> {
    return this.apiProcedureService.acknowledgeErrorDetails(params);
  }
  public auditLogActivity(params): Observable<any> {
    return this.apiProcedureService.auditLogActivity(params);
  }  

}