import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagefacilitiesRoutingModule } from './managefacilities-routing.module';
import { HttpLoaderFactory, SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ManagefacilitiesComponent } from './managefacilities.component';
import { FacilitylinkingComponent } from './facilitylinking/facilitylinking.component';
import { FormsModule } from '@angular/forms';
import { MaterialprimeModule } from '../material.module';




@NgModule({
  declarations: [ManagefacilitiesComponent, FacilitylinkingComponent],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    NgxSpinnerModule,
    HttpClientModule,
    MaterialprimeModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    ManagefacilitiesRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ManagefacilitiesModule { }
