export interface AdminUserInfo {
    imagingCenterName?: any;
    facilityId?: any;
    adminFirstName?: any;
    adminLastName?: any;
    adminUserName?: any;
    adminEmailId?: any;
    adminPhone?: any;
    password?: any;
    confirmPassword?: any;
    MFA?: boolean;
    countryCode?:any

}

export const INITIAL_ADMIN_USER_STATE: AdminUserInfo = {
    imagingCenterName: '',
    facilityId: '',
    adminFirstName: '',
    adminLastName: '',
    adminUserName: '',
    adminEmailId: '',
    adminPhone: '',
    password: '',
    confirmPassword: '',
    countryCode:'',
    MFA: true,

}

export interface ResetPassword {
   
    adminEmailId?: any;
    verificationCode?: any;
    password?: any;
}

export const INITIAL_RESETPASSWORD_STATE: ResetPassword = {
    
    adminEmailId: '',
    verificationCode: '',
    password: ''
}

export interface ResendCodeInfo {
    adminUserName?: any;
    loginFrom?: any;
    password?: any;
}

export const INITIAL_RESENDCODE_STATE: ResendCodeInfo = {
    adminUserName: '',
    loginFrom: '',
    password: ''
}

export interface LoggedUserDetails {
    userName: string;
    preferredUserName: string;
    userRole: string;
    createdBy: string;
    updatedBy: string;
    userStatus: string;
    createdDate: string;
    updatedDate: string;
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    profilePic: string;
    userFacilityAddress: string;
    userFacilityCity: string;
    userFacilityContactLead: string;
    userFacilityEmail: string;
    userFacilityId: number;
    userFacilityName: string;
    userFacilityPhone: string;
    userFacilityState: string;
    userFacilityZipCode: string;
}

export class PopupCancelData {
    displayUploadFile: boolean;
    displaySiteModal:boolean;
    displaySurgeonModal:boolean;
    displayApplicationModal:boolean;
    displaySelectFilesModal: boolean;
}

export class DashboardData {
    linkedFacilityId : number;
    searchText :string;
    targetFacilityIds: [];
    surgeonIds: [];
    applicationIds: [];
    statuses: any;
    fromUploadDate : any;
    toUploadDate : any;
    page:number;
    limit:number;
    column: any;
}

export class settingsData {
    userFacilityId: number;
    isFacilityWide: boolean;
    successDays: number;
    errorDays: number;
}

export class settingsResponse {
    responseStatus: string;
    responseMessage: string;
}

export class errorResponse {
    timeStamp: string | number;
    errorCode: string;
    errors: [];
}

export class HistoryData {
    linkedFacilityId : number;
    searchText :string;
    uploadId: [];
    uploaderId: [];
    surgeonId: [];
    targetFacilityId: [];
    uploadStatus:any = [];
    fromCreatedDate : any;
    toCreatedDate : any;
    page:number;
    limit:number;
    column: any;
}

export class AuditLogData {
    userFacilityId : number;
    searchText : string;
    userName :[];
    fromActionDate : any;
    toActionDate  : any;
    pageno :number;
    limit : number;
    column:any;
}

export class AuditLogExportData {
    actionIds:any[];
    userFacilityId:number;
}

export class SnnFacilityData {
    snnManager: boolean;
    facilityId: number;
  facilityName: string;
}

export class AuditActionData {
    UserActions: any;
}

export class PostAuditExportResponse {
    logFileCreated: boolean;
    responseMessage: string;
}

export class GetAuditExportResponse {
    logfileurl: string;
    updatedDate: string;
}

export class environmentURL {
    hostedUIUrl: string;
    hostedUIlogout: string;
    identityPoolId: string;
    userPoolId: string;
    userPoolWebClientId: string;
    userPoolAwsLogin: string;
    cloudfrontCLPURL: string;
    cloudFrontCLPSignInUrl: string;
    cloudFrontCLPSignOutUrl: string;
    cloudFrontCLPExitAppUrl: string;
}

export class FacilityRecord {
    facilityId: number;
    facilityName: string;
    address: string;
    city: string;
    state: string;
    facilityType: string;
    facilityContactLead: string;
    facilityEmail: string;
    facilityPhone: string;
    activeStatus: string;
    extension: string;
    facilityAdditionalDetails: string;
    zipcode: string;
}

export class DashboardResponseData {
    applicationId: number;
    applicationName: string;
    batchId: string;
    inValidDicomFileCount: number;
    isRead: boolean;
    note: string;
    patientDOB: string;
    patientGender: string;
    patientName: string;
    status: string;
    surgeonFirstName: string;
    surgeonId: string;
    surgeonLastName: string;
    targetFacilityId: number;
    targetFacilityName: string;
    uploadCount: number;
    uploadDate: string;
    uploadId: string;
    validDicomFileCount: number;
}