import { Injectable } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { from, Observable } from 'rxjs';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CloudService {

  private session: any;
  public getSnPermission: any;
  constructor(
    private storageService: StorageService,
    private router: Router,
  ) {
    Amplify.configure(environment);
    Auth.configure(environment);

  }
  public currentSession(): any {
    return Auth.currentSession();
  }

  public amplifySignOut(): Observable<any> {
    return from(Auth.signOut());
  }

  public currentAuthenticatedUser(): any {
    return Auth.currentAuthenticatedUser();
  }

  public login = async (userName: string, password: string, callback: { (err: any, result: any) }) => {
    await Auth.signIn(userName, password)
      .then(result => {
        return callback(null, result);
      })
      .catch(error => {
        return callback(error, null);
      });
  }

  public localToUtc(date?: string) {
    let currentDate
    if (date) {
      currentDate = new Date(date);
    } else {
      currentDate = new Date();
    }
    const convtDate = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes(),
      currentDate.getUTCSeconds()
    )
    const releaseDate = formatDate(convtDate, 'MM/dd/yyyy HH:mm:ss', 'en-US');
    return releaseDate;
  }
  /**
   * @description to convert UTC date time to local date time format
   * @param {*} date
   * @memberof GlobalService
   */
  public formatDateTime(date: string) {
    let correctInput: string = date.indexOf('.0') > -1 ? date.split('.')[0] : date;
    let newDate;
    let convDateTime: string = '';
    if (correctInput.indexOf('Z') > -1) {
      newDate = new Date(correctInput);
    } else {
      let formatedDateTime = correctInput.replace(/-/g, '/');
      newDate = new Date(formatedDateTime + ' UTC');
    }
    if (isNaN(newDate.getTime())) {
      convDateTime = '';
    } else {
      let d = newDate.getDate();
      let m = newDate.getMonth() + 1;
      let yyyy = newDate.getFullYear();
      let dd: string = d > 9 ? d.toString() : '0' + d.toString();
      let mm: string = m > 9 ? m.toString() : '0' + m.toString();
      let hr: string = newDate.getHours() > 9 ?
        newDate.getHours().toString() : '0' + newDate.getHours().toString();
      let min: string = newDate.getMinutes() > 9 ?
        newDate.getMinutes().toString() : '0' + newDate.getMinutes().toString();
      let sec: string = newDate.getSeconds() > 9 ?
        newDate.getSeconds().toString() : '0' + newDate.getSeconds().toString();
      convDateTime = mm + '/' + dd + '/' + yyyy + ' ' + hr + ':' + min + ':' + sec;
    }
    return convDateTime;
  }

  /**
   * @description to convert UTC date time to local date format
   * @param {*} date
   * @memberof CloudService
   */
  public formatOnlyDate(date: string) {
    let correctInput: string = date.indexOf('.0') > -1 ? date.split('.')[0] : date;
    let newDate;
    let convDate: string = '';
    if (correctInput.indexOf('Z') > -1) {
      newDate = new Date(correctInput);
    } else {
      let formatedDate = correctInput.replace(/-/g, '/');
      newDate = new Date(formatedDate + ' UTC');
    }
    if (isNaN(newDate.getTime())) {
      convDate = '';
    } else {
      let d = newDate.getDate();
      let m = newDate.getMonth() + 1;
      let yyyy = newDate.getFullYear();
      let dd: string = d > 9 ? d.toString() : '0' + d.toString();
      let mm: string = m > 9 ? m.toString() : '0' + m.toString();
      convDate = mm + '/' + dd + '/' + yyyy;
    }
    return convDate;
  }

  /**
   * @description To reset the user password
   * @param {*} username
   * @returns aws service will respond with success or error message based on the username.
   * @memberof CloudService
   */
   public forgotPassword(username: any): any{
    return from(Auth.forgotPassword(username));
  }


  /**
   * @description To reset the user password
   * @param {*} username
   * @param {*} code
   * @param {*} newpassword
   * @returns aws service will respond with success or error message based on the username.
   * @memberof CloudService
   */
  public resetPassword(username: any, code: any, newpassword: any): any{
    return from(Auth.forgotPasswordSubmit(username, code, newpassword));
  }

}

