import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadfilesRoutingModule } from './uploadfiles-routing.module';
import { HttpLoaderFactory, SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UploadfilesComponent } from './uploadfiles.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { FormsModule } from '@angular/forms';
import { SearchsurgeonComponent } from './searchsurgeon/searchsurgeon.component';
import { ApplicationComponent } from './application/application.component';
import { ApplicationCardComponent } from './application-card/application-card.component';
import { SelectfilesComponent } from './selectfiles/selectfiles.component';
import { ApplicationNotListedPopUpComponent } from '../applicationnotlistedpopup/applicationnotlistedpopup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UploadErrorComponent } from './upload-error/upload-error.component';
import { SearchFliterPipe } from './search-fliter.pipe';
import { UploadInProgressComponent } from '../uploadinprogress/uploadinprogress.component';
import { UploadSuccessComponent } from '../uploadsuccess/uploadsuccess.component';
import { SearchsurgeonPipe } from './searchsurgeon/searchsurgeon.pipe';

@NgModule({
  declarations: [UploadfilesComponent, ImageUploadComponent, SearchsurgeonComponent, ApplicationComponent,ApplicationCardComponent, SelectfilesComponent,UploadErrorComponent,SearchFliterPipe,UploadInProgressComponent,UploadSuccessComponent, SearchsurgeonPipe,ApplicationNotListedPopUpComponent],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    UploadfilesRoutingModule
  ],
  exports: [UploadSuccessComponent, SearchFliterPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UploadfilesModule { }
