import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddSurgeonModal, INITIAL_ADDSURGEON_MODAL_STATE, INITIAL_MODAL_STATE, Modal} from '../shared/models/dialog.model';
import { SharedStateService } from '../shared/services/shared-state.service';
import {SelectionModel} from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { constantStrings } from '../shared/helpers/constants';
import { AuthService } from '../shared/services/auth.service';
import { UploadSuccessComponent } from '../uploadsuccess/uploadsuccess.component';

@Component({
  selector: 'app-uploadstatus',
  templateUrl: './uploadstatus.component.html',
  styleUrls: ['./uploadstatus.component.scss']
})
export class UploadStatusComponent implements OnInit {
  isSurgeonTrue;
  isDisabled: boolean = true;
  displayedColumns: string[] = ['dateTime', 'facilityName', 'surgeonName','applicationName','fileStatus'];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<any>(false, []);
  showMessage = false;
  showTable = true;
  @Input() modal: Modal = INITIAL_MODAL_STATE;
  public addModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;
  public showUploadProgressModal: boolean;
  uploadFileDetailObj: any;
  @Output() isErrorFlag: EventEmitter<any> = new EventEmitter<any>();
  isError;
  selectedItem: any;
  @ViewChild(UploadSuccessComponent) successComp;
  @Output() successId: EventEmitter<any> = new EventEmitter<any>();
  receiveValue($event){
    this.showUploadProgressModal= $event;
  }
 
  constructor(
    private sharedState: SharedStateService,
    private _liveAnnouncer: LiveAnnouncer,
    private authService: AuthService,
    public router: Router,
    ) { 
      this.showUploadProgressModal = true;   
      let notification = {
        displayUploadFile: true,
        displaySiteModal: false,
        displaySurgeonModal: false,
        displayApplicationModal: false,
        displaySelectFilesModal: false
      }
      this.sharedState.popupCancelData(notification);  
    }

    @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.isSurgeonTrue={
      isDisplaySelectionfacility:false,
      isDisplaySelectionSurgeon:false,
      isDisplaySelectionApplication:false,
      isUploadFailed:false,
      isHelpScreen:false
    }
    this.sharedState.setDashboardStateData(this.isSurgeonTrue);   
    if(this.authService.getItem('uploadFileStatus')){
      this.uploadFileDetailObj = this.authService.getItem('uploadFileStatus');
      this.dataSource = new MatTableDataSource(this.uploadFileDetailObj);
    }    
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

    //This method is for sorting table
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  /**
   * @description To store the selected data 
   * @memberof UploadStatusComponent
   */
  dataSelection(event,listItem){
    if(listItem.fileStatus == constantStrings.CANCELLED){
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
    this.selectedItem = listItem;
    let uploadFileStorage = this.authService.getItem('uploadFileStatus');
      if(uploadFileStorage){
        for(let statusFile of uploadFileStorage){
          if(statusFile.batchId == this.selectedItem?.batchId){
            statusFile.baseFile = constantStrings.UPLOADSTATUS;
          }
        }
      }
      this.authService.setItem('uploadFileStatus', JSON.stringify(uploadFileStorage))
  }
  /**
   * @description To redirect based on the selected status
   * @memberof UploadStatusComponent
   */
  redirectStatus(){
    if(this.selectedItem.fileStatus == constantStrings.PROCESSING){
      this.successId.emit(this.selectedItem);
      this.router.navigate(['/uploadfiles/uploadinprogress'],
      {queryParams : { batchId: this.selectedItem.batchId}});
    } else if(this.selectedItem.fileStatus == constantStrings.UPLOADED){
      this.successId.emit(this.selectedItem);
      this.showUploadProgressModal = false; //Hide Upload progress page content on call of Success modal
      this.addModalUi = {
        display: 'block',
        header: 'Success Modal',
        content: '',
        okButton: 'Save',
        cancelButton: 'Cancel',
        submitFunction: () => { },
        closeFunction: () => {}
      };
      if(this.successComp){
        this.successComp.getSkippedFilesDetails(this.selectedItem.batchId);
      }
    } else if(this.selectedItem.fileStatus == constantStrings.FAILED){
      this.successId.emit(this.selectedItem);
      this.router.navigate(['/uploadfiles/uploadError'],
      {queryParams : { batchId: this.selectedItem.batchId}});
    }
  }
  ngAfterContentChecked(): void {
    //get error flag value from local storage
    this.isError = localStorage.getItem('isError');
  }

}