import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditActionData, errorResponse, LoggedUserDetails, settingsResponse } from '../shared/models/user.model';
import { AuthService } from '../shared/services/auth.service';
import { SharedStateService } from '../shared/services/shared-state.service';
import { UploadService } from '../uploadfiles/upload.service';

@Component({
  selector: 'app-choose-facility',
  templateUrl: './choose-facility.component.html',
  styleUrls: ['./choose-facility.component.scss']
})
export class ChooseFacilityComponent implements OnInit {
  apiError: any;
  getFacilityData: any;
  public showFacilityModal: boolean;
  totalCount: number = 0;
  facilitySelected: number;
  selectedFacility: any;
  facilityListRes: any;
  isDisabled: boolean = true;
  searchText: string;
  noList: boolean = false;
  snnFacility: number;
  snnStateFacilityId: number;
  userDetails: LoggedUserDetails;
  auditAction: AuditActionData;
  errUserDetails: errorResponse;
  actionActivityDetails: settingsResponse;
  userInfo: LoggedUserDetails;
  snnStateFacilityName: string;
  actionText: string;
  receiveList($event) {
    this.noList = $event;
  }
  constructor(
    private sharedState: SharedStateService,
    private uploadService: UploadService,
    private spinnerService: NgxSpinnerService,
    public router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.spinnerService.show();
    //Recieving loggedIn user details from local storage 
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
    }
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    this.getFacilitiesDetails(0);
    this.showFacilityModal = true;
    this.sharedState.snnFacilityStateData$.subscribe(response => {
      if(response && response.facilityId){
        this.snnFacility = response.facilityId;
        this.isDisabled = false;
      }
    });
    if(this.authService.getItem('SNNFacility')){
      this.selectedFacility = this.authService.getItem('SNNFacility');
      this.snnFacility = this.selectedFacility.facilityId;
      this.isDisabled = false;
    }
  }
  /**
   * (description): Function call to get facilities details
   * (parameter) : facilityType
   * (memberof) : component
  **/
  getFacilitiesDetails(facilityType) {
    this.uploadService.chooseFacility(facilityType).subscribe({
      next: result => {
        this.totalCount = 0;
        if (result && result.length) {
          this.getFacilityData = result;
          this.totalCount = result.length;
          if (this.totalCount == 1) {
            this.selectedFacility = result[0];
            this.isDisabled = false;
          }
          this.facilityListRes = this.sortByName(result);
        }
        this.spinnerService.hide();
      }, error: err => {
        this.apiError = err;
        this.spinnerService.hide();
      }
    });
  }
  /**
  * @description should  call to sort function
  * @parameter facility list
  * @returns component
  */
  sortByName = function (users) {
    return users.sort((a, b) => a.facilityName.toLowerCase() > b.facilityName.toLowerCase() ? 1 : -1)
  };
  /**
   * (description): On selecting facility in pop-up this function is called
   * (parameter) : index, selectedFacility
   * (memberof) : component
  **/
  onFacilitySelect(_index: number, facility) {
    this.facilitySelected = _index;
    this.selectedFacility = facility;
    this.snnFacility = facility.facilityId;
    this.isDisabled = false;
  }
  /**
   * (description): On click of selec button this function is called
   * (parameter) : null
   * (memberof) : component
  **/
  onSelect() {
    this.sharedState.snnFacilityStateData$.subscribe(response => {
      if(response && response.facilityId) this.snnStateFacilityId = response.facilityId;
    });
    let object = {
      snnManager: true,
      facilityId: this.selectedFacility ? this.selectedFacility.facilityId : this.snnStateFacilityId,
      facilityName: this.selectedFacility.facilityName
    }
    this.sharedState.snnFacilityDetails(object);
    this.authService.setItem('SNNFacility', JSON.stringify(object));
    if(this.authService.getItem('userDetails') && this.selectedFacility) {
      this.userDetails = this.authService.getItem('userDetails');
      this.userDetails.userFacilityId = this.selectedFacility.facilityId;
      this.userDetails.userFacilityName = this.selectedFacility.facilityName;
      this.authService.setItem('userDetails', JSON.stringify(this.userDetails));
      this.auditLogActivity();
    }
    this.router.navigate(['/filedashboard']);
  }

  /**
   * (description): To call audit log activity function
   * (memberof) : Component
  **/
  auditLogActivity(){
    let action = {
      "userFacilityId" : this.selectedFacility ? this.selectedFacility.facilityId : this.snnStateFacilityId,
      "userName" : this.userInfo.userName,
      "action" : this.auditAction.UserActions.snnFacility + this.selectedFacility.facilityName + "," + " " + this.userInfo.userName,
      "eventOutCome" : 1
    }
    this.uploadService.auditLogActivity(action).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }
}
