import { HttpClient } from '@angular/common/http';
import { Component, OnInit,EventEmitter, Output,ViewChild, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantStrings } from 'src/app/shared/helpers/constants';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { UploadService } from '../upload.service';

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss']
})
export class ApplicationCardComponent implements OnInit {

  cardSelected: number;
  buttonActiveNext = true;
  buttonActivePrev = false;
  notification;
  applicationListSelected
  @Output() isdisableEvent = new EventEmitter<boolean>();
  @Output() applicationSelected =new EventEmitter<any>();

  errorInApplicationList: any;    //Error while fetch application api
  public applicationListRes:any;    //Response from application api
  totalcount:number = 0;    //No of applications
  @Input('facilityId')FacilityId: number;
  surgeonId: number;
  srcData : SafeResourceUrl;
  cardPixel = 250;    //Width in Pixels required to display one card
  clickCount: number;
  pixelCount: number;     //Pixels to move on next/prev arrow click
  selectedSurgeonObject: any;
  imageUrls=[];       //array to store image urls for all applications
  applicationId: number;
  path:any;
  cardIndex: number;
  cardCount= constantStrings.cardsCount;
  
  constructor(private translateService: TranslateService,
    public router: Router,
    private uploadService:UploadService,
    private http:HttpClient,
    private spinnerService: NgxSpinnerService,
    private sharedState: SharedStateService,
    public sanitizer: DomSanitizer
    ) {
     }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    //get surgeon object stored in shared state service
    this.sharedState.surgeonObjectStateData$.subscribe(response => {       //get surgeon object stored in state service
      this.selectedSurgeonObject = response.surgeon;
      if(this.selectedSurgeonObject != undefined){
        this.surgeonId=this.selectedSurgeonObject.username;
      }     
    })
    //get selected application Id stored in shared state service
    this.sharedState.applicationIdStateData$.subscribe(response=>{       //get application object stored in state service
      this.cardSelected = response.selectedApplicationId;
    })
    this.getApplicationDetails(this.FacilityId,this.surgeonId);
   //call application api on click of back on select files screen
    if(this.surgeonId && this.cardSelected){
    this.getApplicationDetails(this.FacilityId,this.surgeonId);
  }
  }

  //Function to get Application API response
  public getApplicationDetails(facilityId, surgeonId): any {
    this.spinnerService.show();   //Show spinner
    this.uploadService.getapplicationlist(facilityId,surgeonId).subscribe({
       next: result => {
        if(result && result.ApplicationDetails.length > 0){
          for(let app of result.ApplicationDetails){
            let stringToSplit = app.applicationName;
            let nameSplit = stringToSplit.split(" ");
            app.splitAppName = nameSplit.slice(0,3);
          }
          this.applicationListRes = this.sortByName(result.ApplicationDetails);   //Response from application api        
          this.totalcount= result.ApplicationDetails.length;   //No of applications/application cards
          if(this.totalcount == 1){
            this.isdisableEvent.emit(false);
            this.cardSelected= this.applicationListRes[0].applicationId;
            this.onSelect(0,this.applicationListRes[0].applicationId);
          } 
          this.clickCount= Math.ceil((this.totalcount/3))-3;    //value of j
          this.j=this.clickCount;
          this.pixelCount= (this.cardPixel * Math.ceil(this.totalcount/3))*(0.16);    //Pixels to move on next/prev arrow click
          this.spinnerService.hide();     //Hide spinner
        } else {
          if(this.totalcount==0){
            document.getElementById('slide').style.left= '0';
          }
          this.spinnerService.hide();     //Hide spinner
        }
      },error: err => {
        this.errorInApplicationList = err;
        this.spinnerService.hide();     //Hide spinner
      }
    });
  }
  /**
  * @description should  call to sort function
  * @parameter application list
  * @returns component
  */
   sortByName = function(users) {
    return users.sort((a,b) => a.applicationName > b.applicationName ? 1 : -1)
  };
  
  //Function to select an application card
  onSelect(_index: number,id:number){
    this.cardSelected= id;
    this.cardIndex= _index;
    this.isdisableEvent.emit(false);
    let application= {
      selectedApplicationId: this.cardSelected,
    }
    this.sharedState.applicationIdData(application);    //send selected application Id to shared state service
    //passing selected application card to application component
    this.applicationListSelected=this.applicationListRes[_index].applicationName;
    this.applicationSelected.emit(this.applicationListSelected);
    this.notification= {
      displaySelectionApplication:this.applicationListSelected,
      displayApplicationId : this.cardSelected
    }
    this.sharedState.applicationSelectionData(this.notification)
  }

  @ViewChild('slide') slide:any;
  j=0;
  i=0;
  //Function called on click of next arrow
  next(){
    if(this.j!=0){
      this.j--;
      this.i=this.i-this.pixelCount;
      this.buttonActivePrev=true;
      this.slide.nativeElement.style.left = this.i + "px";
      this.buttonActiveNext=false;
    }else{
      this.buttonActiveNext=false;
    }
  }

  //Function called on click of previous arrow
  prev(){
    if(this.j!=this.clickCount){
      this.j++;
      this.i=this.i+this.pixelCount;
      this.buttonActiveNext=true;
      this.slide.nativeElement.style.left = this.i + "px";
      this.buttonActivePrev=false;
    }else{
      this.buttonActivePrev=false;
    }
  }
}
