export class DisplayApplicationData {
    displaySelectionfacility:string
    displaySelectionSurgeon:string
    displaySelectionApplication:string;
    selectedApplicationId: number;      //Selected application Id
    displaySurgeonId: number;
    displaySurgeonFName: string;
    displaySurgeonLName: string;
    displayApplicationId: number;
}
