import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.service';
import { UploadStatusComponent } from './uploadstatus.component';

export const uploadStatusRoutes: Routes = [
  {
    path: 'uploadstatus',
    component: UploadStatusComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: UploadStatusComponent, canActivate: [AuthGuard] },
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(uploadStatusRoutes)
  ],
})
export class UploadStatusRoutingModule { }