import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UploadStatusRoutingModule } from './uploadstatus-routing.module';
import { UploadStatusComponent } from './uploadstatus.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpLoaderFactory, SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MaterialprimeModule } from '../material.module';
import { UploadfilesModule } from '../uploadfiles/uploadfiles.module';


@NgModule({
  declarations: [
    UploadStatusComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    MaterialprimeModule,
    NgxSpinnerModule,
    HttpClientModule,
    UploadfilesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    UploadStatusRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UploadStatusModule { }