import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.service';
import { FailedToRetrievedataComponent } from './failed-to-retrievedata/failed-to-retrievedata.component';
import { FiledashboardComponent } from './filedashboard.component';

export const filedashboardRoutes: Routes = [

  {
    path: 'filedashboard',
    component: FiledashboardComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: FiledashboardComponent, canActivate: [AuthGuard] },
    ]
  },
  { path: 'filedashboard/dashboarderror', component: FailedToRetrievedataComponent, canActivate: [AuthGuard]  },
];
export const routing = RouterModule.forRoot(filedashboardRoutes);
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(filedashboardRoutes)
  ],
})
export class FiledashboardRoutingModule { }
