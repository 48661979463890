import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-connectionFailurepopup',
  templateUrl: './connectionFailurepopup.component.html',
  styleUrls: ['./connectionFailurepopup.component.scss']
})
export class ConnectionFailurePopUpComponent implements OnInit {
  public showPopup: boolean = false;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.handleNavigation();
      }
    });

    window.addEventListener('online', this.handleOfflineEvent.bind(this));
    window.addEventListener('offline', this.handleOfflineEvent.bind(this));
  }
  /**
  * (description): method called on offline event
  * (parameter) : null
  * (memberof) : Component
  */
  handleOfflineEvent() {
    this.showPopup = !navigator.onLine;
  }
  /**
    * (description): method called to handle navigation
    * (parameter) : null
    * (memberof) : Component
    */
  public handleNavigation() {
    this.showPopup = !navigator.onLine;
  }
  /**
  * (description): method called on click of close button
  * (parameter) : null
  * (memberof) : Component
  */
  onCancel() {
    this.showPopup = false;
  }
}