import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { constantStrings, statusCodeString } from 'src/app/shared/helpers/constants';
import { INITIAL_MODAL_STATE, Modal } from 'src/app/shared/models/dialog.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { UploadService } from '../upload.service';
import { settingsResponse } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-selectfiles',
  templateUrl: './selectfiles.component.html',
  styleUrls: ['./selectfiles.component.scss']
})
export class SelectfilesComponent implements OnInit {
  uuid = require('uuid');
  @Input() modal: Modal = INITIAL_MODAL_STATE;

  public value = true;
  public flag: boolean;
  public id: any;
  displaySelectionApplication;
  isApplicationSelected=false;
  displaySelectionFacility;
  displaySelectionSurgeon;
  isSurgeonTrue;
  @Output() event = new EventEmitter<boolean>()
  files: any;
  batchId: any;
  uploadFileDetailObj: any;
  displayFacilityId: number;
  selectedFacility: any;
  selectedSurgeon: any;
  selectedApplication: any;
  selectedApplicationId: any;
  userInfo: any;
  batchSize: number;
  size = 0;
  errUserDetails: string;
  filePaths: string;
  createBatchRes: any;
  uploadFileStorage=[];
  filesLength: number;
  facilityDetails: any;
  public uploadDate: Date = new Date();
  showUploadFiles: boolean;
  @Output() isErrorFlag: EventEmitter<number> = new EventEmitter<number>();
  isError: any;
  errorDetails: any;
  actionActivityDetails: settingsResponse;
  auditAction: any;
  cancelBtn: boolean = false;
  fileSizeUnit = 1024;
  receiveValue($isErrorFlag) {
    this.isError = $isErrorFlag;
  }
  constructor(
    public router: Router,
    private actRouter: ActivatedRoute,
    private sharedState: SharedStateService,
    private authService: AuthService,
    private uploadService: UploadService,
  ) {
    this.ssmInfo();
    this.actRouter.queryParams
    .subscribe(params => {
      this.id = params.id;
      if(this.id == constantStrings.reUpload)
    {
      this.flag= false;
      this.cancelBtn = true;
    }
    });
    this.showUploadFiles = true;
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    //Recieving display selection value from state service 
    this.sharedState.popupCancelStateData$.subscribe(response=>{
      if(response?.displaySelectFilesModal){
        this.showUploadFiles= response.displaySelectFilesModal;
      }
    });
    this.sharedState.facilityStateData$.subscribe(response => {
      this.selectedFacility = response;
      this.facilityDetails = response?.selectedFacility;
      this.displaySelectionFacility = response.displaySelectionfacility;
    })

    this.sharedState.surgeonStateData$.subscribe(response => {
      this.selectedSurgeon = response;
      this.displaySelectionSurgeon = response.displaySelectionSurgeon;
    })

    this.sharedState.applicationStateData$.subscribe(response => {
      this.selectedApplication = response;
      this.displaySelectionApplication = response.displaySelectionApplication;
    })

    this.sharedState.applicationIdStateData$.subscribe(response => {
      this.selectedApplicationId = response.selectedApplicationId;
    })

    this.sharedState.uploadStateData$.subscribe(response => {
      this.isApplicationSelected = response.isDisplaySelectionApplication;
    })

    //Recieving loggedIn user details from local storage 
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
    }
   }

  ngOnInit(): void {
    this.sharedState.ssmIdleTimeData$.subscribe(response => { 
      if(response && response.ssmKey == environment.ssmParams.DIACOMTAG){       
          this.uploadService.dicomTagSSM = response.ssmVal.split(',');       
      }  
    }); 
  }

  /**
   * (description): To cancel the file upload
   * (memberof) : SelectfilesComponent
   */
  public onCancel(): void {
    this.isApplicationSelected=false;
    this.isSurgeonTrue={
      isDisplaySelectionSurgeon:true
    }
    this.sharedState.setDashboardStateData(this.isSurgeonTrue);
    let notification = {
      displayUploadFile: false,
      displaySiteModal: false,
      displaySurgeonModal: false,
      displayApplicationModal: true,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
  }
  public popupClose(): void {
    let notification = {
      displayUploadFile: true,
      displaySiteModal: false,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
    let surgeonObject = {
      surgeonSelected: null,
    }
    this.sharedState.surgeonObjectData(surgeonObject);
  }

  /**
   * (description): To reupload the file upload
   * (memberof) : SelectfilesComponent
   */
  public onReuploadCancel(): void {
    this.isApplicationSelected=false;
    this.isSurgeonTrue={
      isDisplaySelectionSurgeon:true
    }
    this.sharedState.setDashboardStateData(this.isSurgeonTrue);
    let notification = {
      displayUploadFile: true,
      displaySiteModal: false,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.router.navigate(['/uploadfiles']);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
  }

  /**
   * (description): Function calls on folder selected to upload
   * (parameter) : event
   * (memberof) : SelectfilesComponent
   */
  selectFile(event: any): any {
      this.files = event.target.files;
      var self = this;
      this.batchId = self.uuid.v4();
      this.filesLength = this.files.length;
      this.batchSize = 0;
      for (let i = 0; i < this.filesLength; i++) {
        this.batchSize = this.batchSize + this.files[i].size;
      }

      this.size = Math.ceil(this.batchSize/this.fileSizeUnit);
      let batchSizeGB = parseFloat((this.batchSize / this.fileSizeUnit / this.fileSizeUnit / this.fileSizeUnit).toFixed(2));      
      if(batchSizeGB > 3){
        let uploadFileStatus = {
          applicationName: this.displaySelectionApplication,
          facilityName: this.displaySelectionFacility,
          surgeonName: this.displaySelectionSurgeon,
          batchId: this.batchId,
          errorReason: constantStrings.FILESIZERR,
          dateTime: this.uploadDate,
          fileStatus: constantStrings.FAILED
        }; 
      let errorDetails={
        isError: true,
        batchId: this.batchId
      }
      this.isError=this.authService.setItem('isError',errorDetails);        
      let uploadFileStatusArr =  JSON.parse(localStorage.getItem('uploadFileStatus')) || [];
      uploadFileStatusArr.push(uploadFileStatus);
      this.authService.setItem('uploadFileStatus', JSON.stringify(uploadFileStatusArr));
      this.router.navigate(
        ['/uploadfiles/uploadError'],         
        { queryParams: { batchId: this.batchId} } ); 
      }else{
        let uploadFileDetailObj = {};
        uploadFileDetailObj = {
          batchId: this.batchId,
          expectedFileCount: this.files.length,
          batchSizeKB: this.size,
          sourceFacilityId: this.userInfo.userFacilityId,
          sourceFacilityName: this.userInfo.userFacilityName,
          targetFacilityId: this.selectedFacility.displayFacilityId,
          targetFacilityName: this.displaySelectionFacility,
          surgeonId: this.selectedSurgeon.displaySurgeonId,
          surgeonFirstName: this.selectedSurgeon.displaySurgeonFName,
          surgeonLastName: this.selectedSurgeon.displaySurgeonLName,
          applicationId: this.selectedApplicationId,
          applicationName: this.displaySelectionApplication,
          uploaderId: this.userInfo.userName,
          uploaderFirstName: this.userInfo.userFirstName,
          uploaderLastName: this.userInfo.userLastName,
          targetFacilityContactLead: this.facilityDetails?.facilityContactLead,
          targetFacilityEmail: this.facilityDetails?.facilityEmail,
          targetFacilityPhone: this.facilityDetails?.facilityPhone,
          sourceFacilityContactLead: this.userInfo.userFacilityContactLead,
          sourceFacilityEmail: this.userInfo.userFacilityEmail,
          sourceFacilityPhone: this.userInfo.userFacilityPhone,
          sourceFacilityState: this.userInfo?.userFacilityState,
          sourceFacilityZipCode: this.userInfo?.userFacilityZipCode,
          sourceFacilityAddress: this.userInfo?.userFacilityAddress,
          sourceFacilityCity: this.userInfo?.userFacilityCity
        };
        this.createBatch(uploadFileDetailObj);
     }
    }

  /**
 * (description): API function call to create batch
 * (parameter) : uploadFileDetailObj
 * (memberof) : SelectfilesComponent
 */
  public async createBatch(params): Promise<any> {
    this.uploadService.createBatch(params).subscribe({
      next: result => {
        this.createBatchRes = result;
        this.auditLogActivity(statusCodeString.COMPLETE);
        if (this.createBatchRes) {
          let uploadFileStatus = {
            batchId: this.batchId,
            applicationName: this.displaySelectionApplication,
            surgeonName: this.displaySelectionSurgeon,
            dateTime: this.uploadDate,
            facilityName: this.displaySelectionFacility,
            fileStatus: constantStrings.PROCESSING,
            expectedFileCount: this.files.length,
            lastFileName: '',
            batchSize: this.batchSize,
            baseFile: '',
            errorReason: '',
            skippedFileCount: ''
          };
          if (this.authService.getItem('uploadFileStatus') && this.selectedFacility.displayFacilityType) {
            let uploadFileStorage = this.authService.getItem('uploadFileStatus');
            uploadFileStorage.push(uploadFileStatus);
            this.authService.setItem('uploadFileStatus', uploadFileStorage)
          } else {
            this.authService.setItem('uploadFileStatus', [uploadFileStatus]);
          }
          this.uploadService.file = this.files;
          this.uploadService.batchid = this.batchId;

          this.router.navigate(['/uploadfiles/uploadinprogress']);
        } else {
          this.router.navigate(['/uploadfiles/uploadError']);
        }
      }, error: err => {
        this.errUserDetails = err;
        this.auditLogActivity(statusCodeString.ERROR);
        let uploadFileStatus = {
            applicationName: this.displaySelectionApplication,
            facilityName: this.displaySelectionFacility,
            surgeonName: this.displaySelectionSurgeon,
            batchId: this.batchId,
            errorReason: constantStrings.APIHANDLEERROR,
            dateTime: this.uploadDate,
            fileStatus: constantStrings.FAILED
        }; 
        let errorDetails={
          isError: true,
          batchId: this.batchId
        }
        this.isError=this.authService.setItem('isError',errorDetails);        
        let uploadFileStatusArr =  JSON.parse(localStorage.getItem('uploadFileStatus')) || [];
        uploadFileStatusArr.push(uploadFileStatus);
        this.authService.setItem('uploadFileStatus', JSON.stringify(uploadFileStatusArr));
        this.router.navigate(['/uploadfiles/uploadError'],         
          { queryParams: { batchId: this.batchId} } ); 
      }
    });
  }

  /**
   * (description): To call audit log activity function
   * (memberof) : SelectfilesComponent
  **/
  auditLogActivity(code){
    let action = {
      "userFacilityId" : this.userInfo.userFacilityId,
      "userName" : this.userInfo.userName,
      "action" : (code == statusCodeString.COMPLETE) ? this.auditAction.UserActions.uploadInitiated + this.batchId :
      this.auditAction.UserActions.uploadFailedTechnical + " " + this.batchId + " " + this.displaySelectionSurgeon + " " +
      this.displaySelectionApplication + " " + this.displaySelectionFacility,
      "eventOutCome" : this.createBatchRes ? 1 : 0
    }
    this.uploadService.auditLogActivity(action).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }
  /**
        * @description to get the SSM parameter values
        * @memberof component
      */
  public ssmInfo() {
    this.authService.getSSMValue(environment.ssmParams.DIACOMTAG);     
  }
}
