import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { constantStrings } from '../../helpers/constants';
import { SharedStateService } from '../../services/shared-state.service';
import { AuthService } from '../../services/auth.service';
import { SidemenuService } from '../sidemenu/sidemenu.service';

@Component({
  selector: 'app-side-menu-footer',
  templateUrl: './side-menu-footer.component.html',
  styleUrls: ['./side-menu-footer.component.scss']
})
export class SideMenuFooterComponent implements OnInit {
  infoTabActive=false;
  helpScreen=false;
  isHelpScreenActive=false;
  showFooterOption:boolean=true;
 

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private sidemenuService : SidemenuService,
    private sharedState: SharedStateService) { 
    this.translateService.setDefaultLang(constantStrings['defaultLanguage']);

    this.sharedState.uploadStateData$.subscribe(response=>{
      this.infoTabActive=response.isInfoPopUp;
      this.isHelpScreenActive=response.isHelpScreen;
    })
  }

  ngOnInit(): void {

  }

    footerNotification(){
      this.showFooterOption = false;
    }
}
