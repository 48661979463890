import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFacility'
})
export class SearchFliterPipe implements PipeTransform {

  transform(value: any, args?: any,key1?: string): any {
    if(!value)return null;
    if(!args)return value;
    if(args==' ') return null;
    args=args.toLowerCase();

    // a*b : search whole list starts with a and ends with b
    if(args.includes("*")){
    let str = args.split("*")[0];
    let strL = args.split("*")[1];
    return value.filter(
      (res: any) =>
        res[key1].toLowerCase().startsWith(str) && res[key1].toLowerCase().endsWith(strL)
        );
   }

  // a?b : search whole list starts with a and ends with b
   if(args.includes("?")){
    let strF = args.split("?")[0];
    let strL = args.split("?")[1];
    return value.filter(
      (res: any) =>
        res[key1].toLowerCase().startsWith(strF) && res[key1].toLowerCase().endsWith(strL)
        );
   }

   //checks whether search text includs in that facility list
    return value.filter(function(data){
      return data.facilityName.toLowerCase().includes(args);
       
    }); 
}
}
