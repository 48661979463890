export class FileDashboardData {
    isErrorDashboard: boolean;
    selectedDashboardItem: any;
    isRecalled: boolean;
    isReadUnreadFailed: boolean;
    uploadId: string;
}

export class FileDashboardRecall {
    selectedDashboardItem: any;
}
