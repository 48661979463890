import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadService } from 'src/app/uploadfiles/upload.service';
import { constantStrings } from '../../helpers/constants';
import { LoggedInUserDetails, LoginData } from '../../models/login.model';
import { AuthService } from '../../services/auth.service';
import { SharedStateService } from '../../services/shared-state.service';

@Component({
  selector: 'app-side-menu-profile',
  templateUrl: './side-menu-profile.component.html',
  styleUrls: ['./side-menu-profile.component.scss']
})
export class SideMenuProfileComponent implements OnInit {
  userName: string;
  position: string;
  userDetails: string;
  errUserDetails: string;
  role: string;
  imageurl: string;
  userstorage: string;
  errorMessage: string;
  profilePicture: string;
  userInfo: LoginData;
  loggedUserName: LoggedInUserDetails;

  constructor(
    private sharedState: SharedStateService,
    private spinnerService: NgxSpinnerService,
    private uploadService:UploadService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    //Fetch names and roles from state service Story 562
    this.sharedState.loginStateData$.subscribe(response => {
      this.userInfo = response; 
    });
    if(this.userInfo && this.userInfo.userName){
      this.userName = this.userInfo.userName;
      this.userDetails = this.userInfo.userFirstName?.slice(0, 1) + ' ' +this.userInfo.userLastName;
      this.role = this.userInfo.userRole;
      this.profilePicture = this.userInfo.profilePic;
      this.imageurl = constantStrings.imageRender + this.userInfo.profilePic;
    } else {
      this.loggedUserName = this.authService.getItem('userDetails');
      this.role = this.loggedUserName.userRole;
      this.getLoggedInUser(this.loggedUserName.userName);
    }  
  }
  /**
   * (description): To call loggedIn user details API
   * (parameter): UserName
   * (memberof) : Component
  **/
  public getLoggedInUser(UserName): any {
    this.spinnerService.show();
    this.uploadService.getLoggedInDetails(UserName, this.role).subscribe({
      error: err => {
        this.errUserDetails = err;
        this.spinnerService.hide();
      } ,next:result => {
        this.userDetails = result.userFirstName.slice(0, 1) + ' ' +result.userLastName;
        this.role = result.userRole;
        this.profilePicture = result.profilePic;
        this.imageurl = constantStrings.imageRender + result.profilePic;
        this.userstorage = result.userName;
        this.sharedState.setLoginStateData(result);     
        this.spinnerService.hide();
      }
    });

  }

}

