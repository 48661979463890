import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pop-up-dialogue',
  templateUrl: './pop-up-dialogue.component.html',
  styleUrls: ['./pop-up-dialogue.component.scss']
})
export class PopUpDialogueComponent implements OnInit {
  public value = false;
  @Output() event = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }
  /**
  * (description): method called on click of close button
  * (parameter) : null
  * (memberof) : Component
  */  
 onCancel(){ 
    this.event.emit(this.value);
  }

}
