import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { constantStrings } from 'src/app/shared/helpers/constants';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { AddSurgeonModal, INITIAL_ADDSURGEON_MODAL_STATE, INITIAL_MODAL_STATE, Modal } from '../../shared/models/dialog.model';
import { ApplicationCardComponent } from '../application-card/application-card.component';


@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {

  public addModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;

  public linkModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;

  applicationMessage: string;
  @Input() facilityId: number;   //get facility id from other component
  surgeonId: number;
  result: any;
  selectedSurgeonObject: any;
  public showApplicationModal: boolean;
  public flag: boolean;
  public id: any;
  applicationListSelected;
  isDisabled: boolean = true;
  notification;
  displaySelectionSurgeon;
  isSurgeonSelected = false;
  displaySelectionFacility;
  isApplicationTrue;
  isFacilityTrue;
  selectedApplicationId: any;
  // displaySelectionApplication;
  receiveValue($event) {
    this.showApplicationModal = $event;
  }
  disableValue($event) {
    this.isDisabled = $event;
  }

  applicationselected($event) {
    this.applicationListSelected = $event;
  }

  noApplication: boolean = false;
  noApplications($event) {
    this.noApplication = $event;
  }
  @ViewChild(ApplicationCardComponent) CardsDetails;

  @Input() modal: Modal = INITIAL_MODAL_STATE;

  public value1 = true;
  @Output() event = new EventEmitter<boolean>()

  constructor(
    private translateService: TranslateService,
    private router: Router, private actRouter: ActivatedRoute,
    private sharedState: SharedStateService
  ) {
    translateService.setDefaultLang(constantStrings.defaultLanguage);
    this.showApplicationModal = true;
    this.sharedState.popupCancelStateData$.subscribe(response => {
      if (response?.displaySurgeonModal) {
        this.showApplicationModal = response.displaySurgeonModal;
      }
    });
    this.actRouter.queryParams
      .subscribe(params => {
        this.id = params.id;
        if (this.id == constantStrings.reUpload) {
          this.flag = false;
        }
      });
    //Receiving display selection value from state service 
    this.sharedState.popupCancelStateData$.subscribe(response => {
      this.showApplicationModal = response.displayApplicationModal;
    })

    this.sharedState.facilityStateData$.subscribe(response => {
      this.displaySelectionFacility = response.displaySelectionfacility;
    })

    this.sharedState.surgeonStateData$.subscribe(response => {
      this.displaySelectionSurgeon = response.displaySelectionSurgeon;
    })

    this.sharedState.uploadStateData$.subscribe(response => {
      this.isSurgeonSelected = response.isDisplaySelectionSurgeon;
    })

  }

  ngOnInit(): void {
    this.translateService.get(['UploadApplication'])
      .subscribe((data) => {
        this.applicationMessage = data.UploadApplication.IDontSeeMsg;
      });
  }

  ngAfterViewInit(): void {
    if (this.id == constantStrings.reUpload) {
      this.flag = false;
      this.selectfile();
    }
    this.sharedState.applicationIdStateData$.subscribe(response => {
      this.selectedApplicationId = response.selectedApplicationId;
      if (this.selectedApplicationId) this.isDisabled = false;
    })
  }
  /**
  * (description): method called on click of back button
  * (parameter) : null
  * (memberof) : Component
  */
  public onCancel(): void {
    this.isSurgeonSelected = false;
    this.isFacilityTrue = {
      isDisplaySelectionfacility: true
    }
    this.sharedState.setDashboardStateData(this.isFacilityTrue);
    let notification = {
      displayUploadFile: false,
      displaySiteModal: false,
      displaySurgeonModal: true,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
  }
  /**
  * (description): method called on click of close icon
  * (parameter) : null
  * (memberof) : Component
  */
  public popupClose(): void {
    this.isSurgeonSelected = false;
    let notification = {
      displayUploadFile: true,
      displaySiteModal: false,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
  }
  /**
  * (description): method called on click of Next button
  * (parameter) : null
  * (memberof) : Component
  */
  public selectfile(): void {
    this.isSurgeonSelected = false;
    this.showApplicationModal = false;
    let notification = {
      displaySiteModal: false,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: true
    }
    this.sharedState.popupCancelData(notification);
    //Passing selected application value to state service
    this.isApplicationTrue = {
      isDisplaySelectionApplication: true
    }
    this.sharedState.setDashboardStateData(this.isApplicationTrue);
    this.addModalUi = {
      display: 'block',
      header: 'Add Surgeon',
      content: '',
      okButton: 'Save',
      cancelButton: 'Cancel',
      submitFunction: () => { },
      closeFunction: () => {
      }
    };
  }
  /**
  * (description): method called on click of dont see application link
  * (parameter) : null
  * (memberof) : Component
  */
  public selectApplicationLink(): void {
    this.noApplication = true;
    this.linkModalUi = {
      display: 'block',
      header: 'Add Surgeon',
      content: '',
      okButton: 'Save',
      cancelButton: 'Cancel',
      submitFunction: () => { },
      closeFunction: () => {
      }
    };
  }


}
