import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HelpScreenComponent } from './help-screen.component';

export const helpscreenRoutes: Routes = [

  {
    path: 'helpcenter',
    component: HelpScreenComponent, canActivate: [],
    children: [
      { path: '', component: HelpScreenComponent, canActivate: [] },
    ]
  }
];
export const routing = RouterModule.forRoot(helpscreenRoutes);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(helpscreenRoutes)
  ],
})
export class HelpScreenRoutingModule { }
