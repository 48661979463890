import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooseFacilityRoutingModule } from './choose-facility-routing.module';
import { ChooseFacilityComponent } from './choose-facility.component';
import { HttpLoaderFactory, SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UploadfilesModule } from '../uploadfiles/uploadfiles.module';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [ChooseFacilityComponent],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    NgxSpinnerModule,
    HttpClientModule,
    FormsModule, 
    UploadfilesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    ChooseFacilityRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChooseFacilityModule { }
