import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantStrings } from 'src/app/shared/helpers/constants';
import { Modal, INITIAL_MODAL_STATE } from 'src/app/shared/models/dialog.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UploadService } from 'src/app/uploadfiles/upload.service';
import { AuditlogService } from '../auditlog.service';

@Component({
  selector: 'app-auditlogfilter',
  templateUrl: './auditlogfilter.component.html',
  styleUrls: ['./auditlogfilter.component.scss']
})
export class AuditlogfilterComponent implements OnInit {

  /**
   * Class Name: HistoryfilterComponent
   * Description: this class is used to filter Surgeon, Sites, Uploader, Patient and Status in filter dashboard screen
   */

  //Object to store selected filters
  public selectedFilters: any = {
    maxDate: Date,
    minDate: Date,
    userName: [],

  }

  @Input() modal: Modal = INITIAL_MODAL_STATE;
  public showUploader: boolean = false;
  public showUsername: boolean = false;
  selectedUsername = [];
  apiUsernameRes = [];
  itemSelected: string;
  public today: Date = new Date();
  public maxDate: Object = new Date(this.today);
  startDate: '';
  endDate: '';
  //event emmitter to emit an event and pass the parameters on emit to another component receiving value
  @Output() event = new EventEmitter<any>();
  public showDate: boolean = false;
  public showStartDate: boolean = false;
  public showEndDate: boolean = false;
  selectedStart: Date;
  selectedEnd: Date;
  minDate: Date;
  chosenDateFlag: boolean = false;
  apiError: any;
  filterDetailsAPI;
  userInfo: any;

  constructor(private uploadService: UploadService,
    private spinnerService: NgxSpinnerService,
    private auditlogService: AuditlogService,
    private authService: AuthService) { }

  ngOnInit(): void {
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
    }
    this.getFilterDetails(this.userInfo.userFacilityId);
  }

  //This method is called on choosing dates
  dateChosen(event, key) {
    let eventPath = event.srcElement.className;
    let chosenStartDate = event.srcElement.innerHTML;
    if (this.selectedEnd) this.maxDate = this.selectedEnd
    if ((chosenStartDate >= 1 && chosenStartDate <= 31) &&
      ((eventPath.includes('mat-calendar-body-cell-content mat-focus-indicator mat-calendar-body-selected')) ||
        (eventPath.includes('mat-calendar-body-cell-content mat-focus-indicator mat-calendar-body-today mat-calendar-body-selected')))) {
      this.chosenDateFlag = true;
    } else {
      this.chosenDateFlag = false;
    }
    if (key == constantStrings.STARTDATE && this.selectedStart && this.chosenDateFlag) {
      this.showStartDate = !this.showStartDate;
      this.minDate = this.selectedStart;
    }
    else if (key == constantStrings.ENDDATE && this.selectedEnd && this.chosenDateFlag) {
      this.showEndDate = !this.showEndDate;
    }
  }

  //This method is used to Hide Show the filter buttons
  toggle(id) {
    if (id == constantStrings.userName) {
      this.showUsername = !this.showUsername;
    } else if (id == constantStrings.DATE) {
      this.showDate = !this.showDate;
    } else if (id == constantStrings.STARTDATE) {
      this.showStartDate = !this.showStartDate;
      this.showEndDate = false;
    } else if (id == constantStrings.ENDDATE) {
      this.showEndDate = !this.showEndDate;
      this.showStartDate = false;
    }
  }

  //This method is used to Select and deselect filters buttons
  onSelect(selectItem, id) {
    this.itemSelected = selectItem;
    if (id == constantStrings.userName) {
      for (let username of this.apiUsernameRes) {
        if (this.itemSelected == username.userName) {
          if (this.selectedUsername.includes(username.userName)) {
            username.isSelected = false;
            this.selectedUsername.splice(this.selectedUsername.indexOf(username.userName), 1);
          }
          else {
            username.isSelected = true;
            this.selectedUsername.push(selectItem);
          }
        }
      }
    }

    else if ((id == constantStrings.STARTDATE || id == constantStrings.ENDDATE) && selectItem == null) {
      this.selectedStart = selectItem;
      this.selectedEnd = selectItem;
      this.minDate = selectItem;
      this.maxDate = this.today;
    }
    //storing selected values of filters
    this.selectedFilters.userName = this.selectedUsername;
    this.selectedFilters.startDate = this.selectedStart;
    this.selectedFilters.endDate = this.selectedEnd;
  }

  //This method is used to pass filter values to filedashboard component on submit and close the modal
  public onSubmit(): void {
    //hiding all displayed values of filters
    this.showUsername = false;
    this.showDate = false;
    this.showStartDate = false;
    this.showEndDate = false;
    //sending selected values for filters
    this.event.emit(this.selectedFilters);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';      //close modal
  }

  //Function to get surgeon API response
  public getFilterDetails(facilityId): any {
    this.spinnerService.show();
    this.auditlogService.auditLogFilterAPI(facilityId).subscribe({
      next: result => {
        this.filterDetailsAPI = result;
        if(this.filterDetailsAPI && this.filterDetailsAPI.filterValues){
          for (let username of this.filterDetailsAPI.filterValues) {
            let userNameDetails = {
              userName: username.userName,
              isSelected: false
            }
            this.apiUsernameRes.push(userNameDetails)
          }
        }
        this.spinnerService.hide();
      }, error: err => {
        this.apiError = err;
        this.spinnerService.hide();
      }
    });
  }
  /**
  * (description): method called to clear the selected filters
  * (parameter) : null
  * (memberof) : Component
  */
  clearAll() {
    this.onSelect(null, constantStrings.STARTDATE);
    this.onSelect(null, constantStrings.ENDDATE);
    this.clearArray(this.apiUsernameRes);
    this.selectedFilters.userName = null;
    this.selectedStart = null;
    this.selectedEnd = null;
  }
  /**
  * (description): method called to clear the selected filters
  * (parameter) : Selected Array
  * (memberof) : Component
  */
  clearArray(Arr) {
    let dataArray = Arr;
    dataArray.map((data, i) => {
      dataArray[i].isSelected = false;
    });
  }
  clearDate(){
    this.selectedStart = null;
    this.selectedEnd = null;
    this.selectedFilters.startDate = this.selectedStart;
    this.selectedFilters.endDate = this.selectedEnd;
    this.minDate = null;
    this.maxDate = new Date();
  }
}
