import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonAuthComponent } from './commonauth/commonauth.component';
import { LoginComponent } from './login/login.component';


export const authRoutes: Routes = [
  {
    path: '',
    component: CommonAuthComponent,
    children: [
      { path: '', redirectTo: '/signin', pathMatch: 'full'},
      { path: 'signin', component: LoginComponent, canActivate: [] },

    ]
  }
];

export const routing = RouterModule.forRoot(authRoutes);
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(authRoutes)
  ],
})
export class AuthenticateRoutingModule { }
