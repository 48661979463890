import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {
  message: any;
  public setTime: any = 3000;
  successMessage: boolean = false;
  type: any;

  constructor(private alertService: AlertService) { }

  ngOnInit(): void{
    this.alertService.getMessage().subscribe(message => { 
      this.message = message; 
      this.type = message?.type
      if(this.type === 'success') {
        setTimeout(() => {this.message = ' '}, 10000);
      }
      
    });
  }


public removeAlert(){
  this.alertService.remove('');
}
}
