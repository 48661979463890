import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UploadInProgressComponent } from '../uploadinprogress/uploadinprogress.component';
import { UploadSuccessComponent } from '../uploadsuccess/uploadsuccess.component';
import { UploadErrorComponent } from './upload-error/upload-error.component';
import { UploadfilesComponent } from './uploadfiles.component';
import { AuthGuard } from '../shared/guards/auth.service';

export const uploadfilesRoutes: Routes = [
  
    { path: 'uploadfiles', component: UploadfilesComponent, canActivate: [AuthGuard] },
    { path: 'uploadfiles/uploadError', component: UploadErrorComponent, canActivate: [AuthGuard]  },
    { path: 'uploadfiles/uploadinprogress', component: UploadInProgressComponent, canActivate: [AuthGuard]  },
    { path: 'uploadsuccess', component: UploadSuccessComponent, canActivate: [AuthGuard]  }
  ];
export const routing = RouterModule.forRoot(uploadfilesRoutes);
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(uploadfilesRoutes)
  ],
})
export class UploadfilesRoutingModule { }
