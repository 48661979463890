import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { constantStrings, statusCodeString } from '../shared/helpers/constants';
import { AddSurgeonModal, INITIAL_ADDSURGEON_MODAL_STATE, INITIAL_MODAL_STATE, Modal } from '../shared/models/dialog.model';
import { AuthService } from '../shared/services/auth.service';
import { SharedStateService } from '../shared/services/shared-state.service';
import { ImageUploadComponent } from '../uploadfiles/image-upload/image-upload.component';
import { UploadService } from '../uploadfiles/upload.service';
import { UploadSuccessComponent } from '../uploadsuccess/uploadsuccess.component';
import { LoggedUserDetails, settingsResponse } from 'src/app/shared/models/user.model';
import * as dicomParser from "dicom-parser";
import { environment } from 'src/environments/environment';
import { BnNgIdleService } from 'bn-ng-idle';
@Component({
  selector: 'app-uploadinprogress',
  templateUrl: './uploadinprogress.component.html',
  styleUrls: ['./uploadinprogress.component.scss'],
})
export class UploadInProgressComponent implements OnInit, OnDestroy {

  @Output() isErrorFlag: EventEmitter<number> = new EventEmitter<number>();
  @Input() modal: Modal = INITIAL_MODAL_STATE;
  public addModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;
  @ViewChild(ImageUploadComponent) FacilityModel;
  public showUploadProgressModal: boolean;
  displaySelectionFacility:string;
  displaySelectionSurgeon:string;
  displaySelectionApplication:string;
  isUploadInProgress = true;
  recallDetails: string;
  errRecallDetails: string;
  errUserDetails: string;
  statusReq: FormData;
  fileSizeUnit: number = 1024;
  loadedFile:string;
  totalFile:string;
  uploadSpeed:number;
  remainingTime = 0;
  TimeinSeconds = 0;
  timeInMinutes= 0;
  @ViewChild(UploadSuccessComponent) SuccessModel;
  fileStatus: string;
  timeFlagCounter = 1;
  uploadFileCount:number = 1;
  isError;
  percentDone: number=0;
  uploadSuccess: boolean;
  progressPercentage: string;
  time_start:number;
  end_time:number;
  timeDuration:number;
  id: string;
  skippedFileCount: number;
  ValidDicomCount: number;
  percentResultLoaded:number;
  percentCount: number;
  uploadFileDetailObj: any;
  percentloaded: number;
  updatedBacthSize: number;
  surgeonRes: any;
  auditAction: any;
  files= [];
  allFiles = [];
  dicomTagsObj = [];
  skippedFiles = [];
  uploadDate : Date = new Date();
  errorMessage: string;
  skippedFileRes: any;
  counter: number = 0;
  readerCount: number;
  filesLength: number;
  dicomTagSSM = [];
  skipMandTagsFlag : boolean = false;
  skipInValidDicomFlag : boolean = false;
  sqsQueueUrl: string;
  receiveValue($event) {
    this.showUploadProgressModal = $event;
  }
  unsubscriptionFileAPI: Subscription;
  facilityStateDataUnsubscription: Subscription;
  applicationStateDataUnsubscription: Subscription;
  surgeonStateDataUnsubscription:Subscription;
  getUploadStatusAPIUnsubscription:Subscription;
  batchId:string;
  errorDetails: any;
  displayFacility: string;
  displaySurgeon: string;
  displayApplication: string; 
  batchSize:number=0;
  loadedBatchSize:number = 0;
  resultLoaded: number = 0;
  timeInHours:number;
  minutes:number;
  flattenedApiId: string;
  actionActivityDetails: settingsResponse;
  userInfo: LoggedUserDetails;
  errOutcome: number = 0;
  successOutcome: number = 1;
  eventOutcome: number;
  auditText: string | number;
  auditId: string | number;
  validDicomFlag: boolean = true;
  constructor(
    private translateService: TranslateService,
    public router: Router,
    private uploadService: UploadService,
    private http: HttpClient,
    private spinnerService: NgxSpinnerService,
    private sharedState: SharedStateService,
    private authService: AuthService,
    private actRouter: ActivatedRoute,
    private bnIdle: BnNgIdleService) {
    this.showUploadProgressModal = true;      
    this.actRouter.queryParams
      .subscribe(params => {
        this.id = params.batchId;
      });
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    }); 

    //Recieving display selection value from state service
    this.facilityStateDataUnsubscription = this.sharedState.facilityStateData$.subscribe(response => {
      this.displayFacility = response.displaySelectionfacility;
    })

    this.surgeonStateDataUnsubscription = this.sharedState.surgeonObjectStateData$.subscribe(response => {       //get surgeon object stored in state service
      this.surgeonRes = response.surgeon;
      this.displaySurgeon = this.surgeonRes.firstname + ' ' + this.surgeonRes.lastname;
    })

    this.applicationStateDataUnsubscription = this.sharedState.applicationStateData$.subscribe(response => {
      this.displayApplication = response.displaySelectionApplication;
    })   
    //Recieving loggedIn user details from local storage 
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
    }

    let uploadFileStorage = this.authService.getItem('uploadFileStatus');
    if (uploadFileStorage) {
      for (let statusFile of uploadFileStorage) {
        this.batchSize = statusFile.batchSize;
      }
    }
  }

  ngOnInit(): void { 
    //Getting Sqs queue url from SSM parameter
    this.authService.getSSMValue(environment.ssmParams.SQSQUEUEURL);  
    this.sharedState.ssmIdleTimeData$.subscribe(response => { 
      if(response && response.ssmKey == environment.ssmParams.SQSQUEUEURL){       
        this.sqsQueueUrl = response.ssmVal;       
      }  
    }); 
    this.dicomTagSSM = this.uploadService.dicomTagSSM; 
    let uploadFileStorage = this.authService.getItem('uploadFileStatus');
    if (uploadFileStorage) {
      for (let statusFile of uploadFileStorage) {
        if (this.id) {
          if (statusFile.batchId == this.id) {
            this.displaySelectionApplication = statusFile.applicationName;
            this.displaySelectionFacility = statusFile.facilityName;
            this.displaySelectionSurgeon = statusFile.surgeonName
            if(!(statusFile.facilityName)){
              this.router.navigate(['/uploadfiles'])
            }
          }
        } else {
          this.displaySelectionApplication = this.displayApplication;
          this.displaySelectionFacility = this.displayFacility;
          this.displaySelectionSurgeon = this.displaySurgeon;
          if(!(this.displayFacility)){
            this.router.navigate(['/uploadfiles'])
          }
        }
        
      }
    } 
      
    if(!this.id){
      this.processFile();   
    }  
    //When we click on any uploaded record from Upload status screen to check the status
    if(this.id != '' && this.id){
    this.sharedState.uploadProgressData$.subscribe(response => {  
        let uploadFilesStorage = this.authService.getItem('uploadFileStatus');
        if(uploadFilesStorage){
          for(let statusFile of uploadFilesStorage){
            if(statusFile.batchId == this.id){
              if(statusFile.fileStatus == constantStrings.PROCESSING) { 
                this.showUploadProgressModal = true;  
                this.percentDone  = statusFile.percentDone;
                this.loadedFile = statusFile.loadedFile;
                this.totalFile = statusFile.totalFile;
                this.TimeinSeconds = statusFile.TimeinSeconds;
                this.timeInMinutes = statusFile.timeInMinutes;
                this.timeInHours = statusFile.timeInHours;
                this.progressPercentage = statusFile.progressPercentage;
              }
              if(statusFile.fileStatus == constantStrings.UPLOADED && this.showUploadProgressModal) {  
                this.id = '';
                this.showUploadProgressModal = false; //Hide Upload progress page content on call of Success modal        
                this.addModalUi = {       //Success modal defined
                  display: 'block',
                  header: 'Upload File Success',
                  content: '',
                  okButton: 'Save',
                  cancelButton: 'Cancel',
                  submitFunction: () => { },
                  closeFunction: () => {
                  }
                };
              }
            }
          }
        }  
      }); 
    }        
  }
  /**
 * (description):  process file for Dicom parse and upload the valid dicom files
 **/
  processFile(){    
    this.files = this.uploadService.file;
    if(this.files){
      this.filesLength = this.files.length
    }    
    this.batchId = this.uploadService.batchid;  
    for(let i = 0; i < this.filesLength ;i++){
      this.doDicomParse(this.files[i], this.batchId);
    } 
  }
   /**
 * (description):  To parse dicom file
 **/
  doDicomParse(dicomImg: File, batchId: any) {    
    this.allFiles = [];
    this.dicomTagsObj = [];
    this.skippedFiles = [];
    let filePaths = `${this.files[this.counter].webkitRelativePath}`;
    this.readerCount = 0;    
    try {      
      this.errorDetails = localStorage.getItem('isError');
      if (dicomImg != null && (this.errorDetails?.batchId != batchId)) {
        const reader = new FileReader();        
        reader.readAsArrayBuffer(dicomImg);
        reader.onload = () => {                   
          let arrayBuffer: any = reader.result;
          let byteArray = new Uint8Array(arrayBuffer);
          try {
            var dataSet = dicomParser.parseDicom(byteArray);
            let obj = {
              fileName: dicomImg,
              batchSize: dicomImg.size,
            }
            this.validDicomFlag = true;   
            for(let tag of this.dicomTagSSM){
              if(this.findTags(dataSet.string(tag)) && this.validDicomFlag){
                this.validDicomFlag = true;
              } else {
                this.validDicomFlag = false;
              }
            }
            if(this.validDicomFlag){
              this.dicomTagsObj.push(obj);
            } else {
              this.skippedFiles.push(obj);
              let requestParams = {
                batchId:batchId,
                fileName:dicomImg.name,
                filePath:filePaths
              }
              this.skipMandTagsFlag = true;
              this.skippedFileAPI(requestParams);
              this.allFilesSkipped(this.filesLength, batchId);
            }   
          }
          catch (ex) {
            let obj = {
              fileName: dicomImg.name,
              batchId: batchId,
              batchSize: dicomImg.size,
            }
            this.skippedFiles.push(obj);
            let requestParams = {
              batchId:batchId,
              fileName:dicomImg.name,
              filePath:filePaths
            }
            this.skipInValidDicomFlag = true;
            this.skippedFileAPI(requestParams);
            this.allFilesSkipped(this.filesLength, batchId);
          }
          this.allFiles.push(dicomImg);          
          this.readerCount++;
             
          if(this.readerCount == this.filesLength){ 
            let notification = {
              dicomFolder: this.allFiles,
              validDicom: this.dicomTagsObj,
              invalidDicom: this.skippedFiles,
              batchId: batchId,
              totalFileCount:this.filesLength,
              uploadDate : this.uploadDate
            };
            this.sharedState.setDicomStateData(notification);
            if(this.dicomTagsObj.length !=0){
              this.time_start = new Date().getTime();
              this.uploadService.startTime = this.time_start;
              this.getUploadStatusAPI(batchId);
            }  
          }   
        }
      }
    
    }
    catch (ex) {
      //Error parsing byte stream
      this.errorMessage = ex;
      this.auditLogActivity(dicomImg.name, this.errOutcome);
    }
  }
  findTags(value) {
    if (value == '' && value == undefined && value == null) {
      return false;
    } else {
      return value;
    }
  }  
 
  /**
   * @description To check if all files are skipped 
   * @param {*} filesLength
   */
  allFilesSkipped(filesLength, batchId){
    if(this.skippedFiles.length==filesLength){
      let errorDetails={
        isError: true,
        batchId: batchId
      }
      this.isError=this.authService.setItem('isError',errorDetails);
      this.isErrorFlag.emit(this.isError);
      let uploadFileStorage = this.authService.getItem('uploadFileStatus');
      for(let statusFile of uploadFileStorage){
        if(statusFile.batchId == batchId){
          statusFile.fileStatus= constantStrings.FAILED;
          statusFile.errorReason = constantStrings.ALLSKIPPED;
          if(this.skipMandTagsFlag &&  this.skipInValidDicomFlag){
            statusFile.skipTags  = true;
          }else if(this.skipMandTagsFlag &&  !this.skipInValidDicomFlag){
            statusFile.skipMandTags  = true;
          }else{
            statusFile.skipInValidDicom  = true;
          }
        }
      }      
      this.authService.setItem('uploadFileStatus', JSON.stringify(uploadFileStorage));
      this.auditLogActivity(constantStrings.ALLSKIPPED ,this.errOutcome);
      this.router.navigate(['/uploadfiles/uploadError']);
    }
  }

    /**
   * (description): function is called to send skipped file details
   * (parameter) : fileName, filePath, batchId   
   */
  public async skippedFileAPI(params): Promise<any> {
    this.auditLogActivity(params.fileName, this.errOutcome);
    this.uploadService.skippedFile(params).subscribe({      
      next: result => {
        this.skippedFileRes = result;
      },error: err => {
        this.errUserDetails = err;
      }
    });
  }
  public openFacilityModal(): void {
    let notification = {
      displayUploadFile: false,
      displaySiteModal: true,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.isUploadInProgress = false;
    this.router.navigate(
      ['/uploadfiles'],         //nativate to upload files page
      { queryParams: { id: constantStrings.nextUpload } } //unique id passed 'nextUpload' along with path
    );
  }
  /**
  * (description): function called on click of back button
  * (parameter) : null
  * (memberof) : Component
  */
  onCancel() {
    this.isUploadInProgress = false;
    this.applicationStateDataUnsubscription.unsubscribe();
    this.facilityStateDataUnsubscription.unsubscribe();
    this.surgeonStateDataUnsubscription.unsubscribe();
    const body = { "batchId": this.batchId || this.id}
    this.recallUploadAPI(body);
  }

  /**
  * (description): API function call to recall uploaded file
  * (parameter) : Uploadid, batchId
  * (memberof) : Component
  */
  public recallUploadAPI(params): any {
    this.uploadService.recallUploadAPI(params).subscribe({
      next: result => {
        this.recallDetails = result;
        this.auditLogActivity(constantStrings.CANCELLED, this.successOutcome);
        this.uploadFileDetailObj = this.authService.getItem('uploadFileStatus');
        if(this.uploadFileDetailObj.length > 0){
          for(let i = 0;i < this.uploadFileDetailObj.length;i++){
              if(this.uploadFileDetailObj[i].batchId == this.batchId || this.uploadFileDetailObj[i].batchId == this.id){
              this.uploadFileDetailObj[i].fileStatus= constantStrings.CANCELLED;
              }
          }
          this.authService.setItem('uploadFileStatus', this.uploadFileDetailObj);
        }
        // this.spinnerService.hide();
      }, error: err => {
        this.errRecallDetails = err;
        this.auditLogActivity(constantStrings.CANCELLED, this.errOutcome);
        // this.spinnerService.hide();
        //Set error flag value to local storage
      }
    });
  }
  /**
  * (description): API call to get upload status
  * (parameter) : null
  * (memberof) : Component
  */
    public getUploadStatusAPI(batchId) {
    let file,fileName;
    if(this.dicomTagsObj){
      file = this.dicomTagsObj[this.counter].fileName;
      fileName = this.dicomTagsObj[this.counter].fileName.name;
      this.statusReq = new FormData();
      this.statusReq.append('actualFile', file,file['name']);
      this.statusReq.append('batchId', batchId); 
      this.ValidDicomCount = this.dicomTagsObj.length;
      this.skippedFileCount = this.skippedFiles.length;
    }
   this.uploadService.getUploadStatusAPI(this.statusReq).subscribe({
    error: err => {
        this.errUserDetails = err;
        let errorDetails = {
          isError: true,
          batchId: this.batchId
        }
        this.isError = this.authService.setItem('isError', errorDetails);
        this.isErrorFlag.emit(this.isError);
        let uploadFileStorage = this.authService.getItem('uploadFileStatus');
        if (uploadFileStorage) {
          for (let statusFile of uploadFileStorage) {
            if ((statusFile.batchId == this.batchId) && (statusFile.fileStatus == constantStrings.PROCESSING)) {
              statusFile.fileStatus = constantStrings.FAILED;
              statusFile.lastFileName = fileName;
              statusFile.errorReason = constantStrings.TECHNICALERROR;
              this.auditLogActivity(statusCodeString.ERROR, this.errOutcome);
              this.router.navigate(['/uploadfiles/uploadError']);
            }
          }
        }
        this.authService.setItem('uploadFileStatus', JSON.stringify(uploadFileStorage));
        this.applicationStateDataUnsubscription.unsubscribe();
        this.facilityStateDataUnsubscription.unsubscribe();
        this.surgeonStateDataUnsubscription.unsubscribe();       
      },next: result => {
      if (result.type === HttpEventType.UploadProgress) {
        if (result.loaded > this.resultLoaded) this.resultLoaded = result.loaded;
        this.percentResultLoaded = Math.round(100 * this.resultLoaded / result.total);
        this.end_time = new Date().getTime();
        this.timeDuration = (this.end_time - this.time_start) / 1000; //Calculating time duration of data uploaded 
        this.uploadSpeed = this.timeFlagCounter / this.timeDuration; //Calculating upload speed 
        this.remainingTime = (this.ValidDicomCount - this.timeFlagCounter) / this.uploadSpeed; //Calculating remaining time to upload data
        this.TimeinSeconds = Math.floor(this.remainingTime % 60); //Changing time from seconds to Minutes and Seconds
        this.timeInMinutes = Math.floor(this.remainingTime / 60);
        if(this.timeInMinutes>60){
          this.timeConvert(this.timeInMinutes)
        }
        this.progressPercentage = this.percentDone + "%";
        this.loadedBatchSize = (this.percentDone * this.batchSize) / 100;
        if(this.loadedBatchSize<this.batchSize) this.updatedBacthSize =this.loadedBatchSize;
        this.loadedFile = this.getFileSize(this.updatedBacthSize) + " " + this.getFileSizeUnit(this.updatedBacthSize);
        this.totalFile = this.getFileSize(this.batchSize) + " " + this.getFileSizeUnit(this.batchSize); 

        let uploadFileStorage = this.authService.getItem('uploadFileStatus');
        if(uploadFileStorage){
          for(let statusFile of uploadFileStorage){
            if(statusFile.batchId == batchId){              
              statusFile.percentDone = this.percentDone;
              statusFile.loadedFile = this.loadedFile;
              statusFile.totalFile = this.totalFile;
              statusFile.TimeinSeconds = this.TimeinSeconds;
              statusFile.timeInMinutes = this.timeInMinutes;
              statusFile.timeInHours = this.timeInHours;
              statusFile.progressPercentage = this.progressPercentage;
            }
          }
        }  
        this.authService.setItem('uploadFileStatus', JSON.stringify(uploadFileStorage));        
        this.sharedState.uploadProgressPer(this.percentDone);
        //Resetting the idle time Timer while upload is in Progress to avoid inactivity timeout
        this.bnIdle.resetTimer();
      }
      if (result?.status == 200 && result?.type == HttpEventType.Response) {
        let uploadFileStor = this.authService.getItem('uploadFileStatus');
        let batchStatus;
        if (uploadFileStor) {
          for (let status of uploadFileStor) {
            if(batchId == status.batchId){
              batchStatus = status.fileStatus;
            }
          }
        }
        if (this.timeFlagCounter > this.uploadFileCount) this.uploadFileCount = this.timeFlagCounter;
        this.percentCount = Math.round(100 * this.uploadFileCount / this.ValidDicomCount);        
        this.percentDone=this.percentCount;
        this.counter++;
        if(this.counter <= (this.ValidDicomCount-1) && (batchStatus == constantStrings.PROCESSING)){       
          this.timeFlagCounter++;          
          this.getUploadStatusAPI(batchId);
        }       
      }
      if (this.counter == this.ValidDicomCount) {
        this.ValidDicomCount = 0;
        this.timeFlagCounter = 0;
        this.uploadSuccess = true;
        let uploadFileStorage = this.authService.getItem('uploadFileStatus');
        if (uploadFileStorage) {
          for (let statusFile of uploadFileStorage) {
            if ((statusFile.batchId == batchId) && (statusFile.fileStatus == constantStrings.PROCESSING)) {
              statusFile.fileStatus = constantStrings.UPLOADED;
              statusFile.baseFile = constantStrings.UPLOADFILE;
              statusFile.skippedFileCount = this.skippedFileCount;
            }
          }
        }
        this.authService.setItem('uploadFileStatus', JSON.stringify(uploadFileStorage));       
        this.sharedState.uploadProgressPer(this.percentDone);
        if(this.flattenedApiId != batchId){
          this.flattenedApiId = batchId;
          this.authService.getSQSValue(batchId, this.sqsQueueUrl);
          this.auditLogActivity(statusCodeString.UPLOADED, this.successOutcome);
        }
        this.isUploadInProgress = false;
        this.showUploadProgressModal = false; //Hide Upload progress page content on call of Success modal
        this.addModalUi = {       //Success modal defined
          display: 'block',
          header: 'Upload File Success',
          content: '',
          okButton: 'Save',
          cancelButton: 'Cancel',
          submitFunction: () => { },
          closeFunction: () => {
          }
        };
      }
     }
    });

  }

  //convert file size from byte to KB,MB, and GB accordingly
  getFileSize(fileSize: number): number {
    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSize = parseFloat((fileSize / this.fileSizeUnit).toFixed(2));
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      }
    }

    return fileSize;
  }

  //Find the unit of fileSize 
  getFileSizeUnit(fileSize: number) {
    let fileSizeInWords = 'bytes';

    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit) {
        fileSizeInWords = 'bytes';
      } else if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = 'KB';
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = 'MB';
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = 'GB';
      }
    }

    return fileSizeInWords;
  }
  /**
   * (description): function call to convert time
   * (parameter) : batchId
   * (memberof) : Component
   */
  timeConvert(n) {
    let num = n;
    let hours = (num / 60);
    this.timeInHours = Math.floor(hours);
    this.minutes = Math.round((hours - this.timeInHours) * 60);
  }
  /**
   * (description): To call audit log activity function
   * (memberof) : Component
  **/
  auditLogActivity(code, outcome){
    switch (code) {
      case constantStrings.CANCELLED:
        this.auditText = this.auditAction.UserActions.uploadCancelled;
        this.auditId = this.batchId + " " + this.surgeonRes.username + " " +
          this.displayApplication + " " + this.displayFacility;
      break;
      case statusCodeString.ERROR:
        this.auditText = this.auditAction.UserActions.uploadFailedTechnical;
        this.auditId = this.batchId + " " + this.surgeonRes.username + " " +
        this.displayApplication + " " + this.displayFacility;
      break;
      case statusCodeString.UPLOADED:
        this.auditText = this.auditAction.UserActions.uploadSuccessfull;
        this.auditId = this.batchId + " " + this.surgeonRes.username + " " +
          this.displayApplication +" " + this.displayFacility;
      break;
      case constantStrings.ALLSKIPPED:
        this.auditText = this.auditAction.UserActions.uploadFailedSkipped;
        this.auditId  = this.batchId + " " + this.surgeonRes.username + " " +
        this.displayApplication + " " + this.displayFacility;    
      break;
      default:
        this.auditText = this.auditAction.UserActions.dicomValidation;
        this.auditId  = this.batchId + " " + code;  
      break;
    }
    this.eventOutcome = outcome;
    let actionOutcome = {
      "userFacilityId" : this.userInfo.userFacilityId,
      "userName" : this.userInfo.userName,
      "action" : this.auditText+''+this.auditId,
      "eventOutCome" : this.eventOutcome
    }
    this.uploadService.auditLogActivity(actionOutcome).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }   
  ngOnDestroy():void {
    this.applicationStateDataUnsubscription.unsubscribe();
    this.facilityStateDataUnsubscription.unsubscribe();
    this.surgeonStateDataUnsubscription.unsubscribe();
  }

}