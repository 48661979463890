export class AddSurgeonModal {
    display: string | undefined;
    header: string | undefined;
    content: string | undefined;
    okButton: string | undefined;
    cancelButton: string | undefined;
    submitFunction: (() => void) | undefined;
    closeFunction: (() => void) | undefined;
    data?: any;
}


export const INITIAL_ADDSURGEON_MODAL_STATE: AddSurgeonModal = {
    display: 'none',
    header: '',
    content: '',
    okButton: '',
    cancelButton: '',
    submitFunction: undefined,
    closeFunction: undefined,
    data: undefined
};

export class Modal {
    display: string | undefined;
    header: string | undefined;
    content: string | undefined;
    okButton: string | undefined;
    cancelButton: string | undefined;
    submitFunction: (() => void) | undefined;
    closeFunction: (() => void) | undefined;
    data?: any;
}

export const INITIAL_MODAL_STATE: Modal = {
    display: 'none',
    header: '',
    content: '',
    okButton: '',
    cancelButton: '',
    submitFunction: undefined,
    closeFunction: undefined,
    data: undefined
};

export interface Dialog {
    show: boolean,
    content: string,
    eula: boolean,
    okButton: string,
    okFunction: () => void,
    cancelButton: string,
    cancelFunction: () => void,
    closeFunction: () => void
}
export const INITIAL_DAILOG_STATE: Dialog = {
    show: false,
    content: null,
    eula: false,
    okButton: null,
    okFunction: null,
    cancelButton: null,
    cancelFunction: null,
    closeFunction: null
}

export class SuccessModal {
    display: string;
    content: string;
    okButton: string;
    submitFunction: () => void;
}

export const INITIAL_SUCCESSMODAL_STATE: SuccessModal = {
    display: 'none',
    content: '',
    okButton: '',
    submitFunction: null
}

export class RecallModal {
    display: string;
    content: string;
    okButton: string;
    cancelButton: string;
    submitFunction: () => void;
    closeFunction: () => void;
    header: string;
}

export const INITIAL_RECALLMODAL_STATE: RecallModal = {
    display: 'none',
    content: '',
    okButton: '',
    cancelButton: '',
    submitFunction: null,
    closeFunction: null,
    header: ''
}


export class PwdResetModal {
    display: string;
    header: string;
    content: string;
    okButton: string;
    cancelButton: string;
    submitFunction: () => void;
    closeFunction: () => void;
    data?: any
}

export const INITIAL_PWDRESET_MODAL_STATE: PwdResetModal = {
    display: 'none',
    header: '',
    content: '',
    okButton: '',
    cancelButton: '',
    submitFunction: null,
    closeFunction: null,
    data: undefined
}


export class ReloginModal {
    display: string;
    content: string;
    okButton: string;
    submitFunction: () => void;
    closeFunction: () => void;
}

export const INITIAL_RELOGIN_MODAL_STATE: ReloginModal = {
    display: 'none',
    content: '',
    okButton: '',
    submitFunction: null,
    closeFunction: null
}

export interface ResetPassword {
    userType?: string;
    userName?: string;
    verificationCode?: string;
    password?: string;
}

export const INITIAL_RESETPASSWORD_STATE: ResetPassword = {
    userType: '',
    userName: '',
    verificationCode: '',
    password: ''
}

//modal for filter
export class FilterModal {
    display: string | undefined;
    header: string | undefined;
    content: string | undefined;
    doneButton: string | undefined;
    submitFunction: (() => void) | undefined;
    data?: any;
    okButton: null;
    cancelButton: null;
    closeFunction: null;
}

export const INITIAL_FILTERMODAL_STATE: FilterModal = {
    display: 'none',
    header: '',
    content: '',
    doneButton: '',
    submitFunction: undefined,
    data: undefined,
    okButton: null,
    cancelButton: null,
    closeFunction: null
};

//modal for history details
export class HistoryDetailsModal {
    display: string | undefined;
    header: string | undefined;
    content: string | undefined;
    closeButton: string | undefined;
    closeFunction: (() => void) | undefined;
    data?: any;
}

export const INITIAL_HistoryDetailsModal_STATE: HistoryDetailsModal = {
    display: 'none',
    header: '',
    content: '',
    closeButton: '',
    closeFunction: undefined,
    data: undefined,
};

export class ErrorModal {
    display: string | undefined;
    header: string | undefined;
    content: string | undefined;
    contentRetry: string | undefined;
    okButton: string | undefined;
    cancelButton: string | undefined;
    submitFunction: (() => void) | undefined;
    closeFunction: (() => void) | undefined;
    data?: any;
}

export const INITIAL_ERROR_STATE: ErrorModal = {
    display: 'none',
    header: '',
    content: '',
    contentRetry: '',
    okButton: '',
    cancelButton: '',
    submitFunction: undefined,
    closeFunction: undefined,
    data: undefined
};

export class AuditlogModal {
    display: string | undefined;
    content: string | undefined;
    cancelButton: string | undefined;
    closeFunction: (() => void) | undefined;
    data?: any;
    header: string | undefined;
    okButton: string | undefined;
    submitFunction: (() => void) | undefined;
}

export const INITIAL_AUDITLOGMODAL_STATE: AuditlogModal = {
    display: 'none',
    content: '',
    cancelButton: '',
    closeFunction: undefined,
    data: undefined,
    header: '', 
    okButton: '',
    submitFunction: undefined
};

export class DownloadReadyModal {
    display: string | undefined;
    content: string | undefined;
    cancelButton: string | undefined;
    closeFunction: (() => void) | undefined;
    data?: any;
    header: string | undefined;
    okButton: string | undefined;
    submitFunction: (() => void) | undefined;
}

export const INITIAL_DOWNLOADREADYMODAL_STATE: DownloadReadyModal = {
    display: 'none',
    content: '',
    cancelButton: '',
    closeFunction: undefined,
    data: undefined,
    header: '',
    okButton: '',
    submitFunction: undefined
};

export class ExpirationModal {
    display: string | undefined;
    content: string | undefined;
    cancelButton: string | undefined;
    closeFunction: (() => void) | undefined;
    header: string | undefined;
    okButton: string | undefined;
    submitFunction: (() => void) | undefined;
    data?: any;
}

export const INITIAL_EXPIRATIONMODAL_STATE: ExpirationModal = {
    display: 'none',
    content: '',
    cancelButton: '',
    closeFunction: undefined,
    data: undefined,
    header: '',
    okButton: '',
    submitFunction: undefined
};