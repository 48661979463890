export class UploadData {
    fileUploadError: any;
    fileUploadInProgress: any;
    position: any;
    userName : any;
    group: any;
    isDisplaySelectionfacility:boolean;
    isDisplaySelectionSurgeon:boolean;
    isDisplaySelectionApplication:boolean;
    isUploadFailed:boolean;
    isInfoPopUp:boolean;
    isHelpScreen:boolean;

}
