import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INITIAL_MODAL_STATE, Modal } from 'src/app/shared/models/dialog.model';

@Component({
  selector: 'app-compilationpopup',
  templateUrl: './compilationpopup.component.html',
  styleUrls: ['./compilationpopup.component.scss']
})
export class CompilationpopupComponent implements OnInit {
  @Input() modal: Modal = INITIAL_MODAL_STATE;
  public value = false;
  @Output() event = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  public onCancel(): void {
    this.event.emit(this.value);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
  }
}
