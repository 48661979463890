export class AddnewUserModal {
    display: string;
    header: string;
    content: string;
    okButton: string;
    cancelButton: string;
    submitFunction: () => void;
    closeFunction: () => void;
    data?: any;
}

export const INITIAL_ADDNEWUSER_MODAL_STATE: AddnewUserModal = {
    display: 'none',
    header: '',
    content: '',
    okButton: '',
    cancelButton: '',
    submitFunction: null,
    closeFunction: null,
    data: undefined
};


export class ConfirmModal {
    display: string;
    content: string;
    okButton: string;
    cancelButton: string;
    submitFunction: () => void;
    closeFunction: () => void;
    data?: any
}

export const INITIAL_CONFIRMMODAL_STATE: ConfirmModal = {
    display: 'none',
    content: '',
    okButton: '',
    cancelButton: '',
    submitFunction: null,
    closeFunction: null,
    data: undefined
};