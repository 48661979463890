import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.service';
import { HistoryComponent } from './history.component'

export const historyRoutes: Routes = [

  {
    path: 'history',
    component: HistoryComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: HistoryComponent, canActivate: [AuthGuard] },
    ]
  }
];
export const routing = RouterModule.forRoot(historyRoutes);
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(historyRoutes)
  ],
})

export class HistoryRoutingModule { }

