export const environment = {
  appVersion: 'Version 1.0.0',
  baseUrl: 'https://api.development.bridge.faiplan-dev.com',
  baseUrlClp: 'https://api.development.bridge.faiplan-dev.com',
  baseUrlClpSnn: 'https://snn.api.development.bridge.faiplan-dev.com',
  baseUnauthUrl: 'https://snn.api2.development.bridge.faiplan-dev.com',
  production: true,
  lensSenderEmail: 'snnoreply@maildrop.cc',
  region: 'us-east-2',
  apiVersionCognito: '2016-04-18',
  apiVersionSqs: '2012-11-05',
  inactivityIdleTime: 900,
  ssmParams : {
    IDLETIME : "/bridge-development/image-upload-service_development/inactivityTime_login",
    DIACOMTAG : "/bridge-development/image-upload-service_development/validDicomTags",
    RECALLTIME : "/bridge-development/image-upload-service_development/recallTime",
    AUDITINTERVALTIME : "/bridge-development/image-upload-service_development/auditInterval",
    APPVERSION: "/bridge-development/image-upload-service_development/appVersionInfo",
    SQSQUEUEURL: "/bridge-development/DUU_Group_by_patient/group-by-patient-queue-url",
  },
  //ALL ROLES EXPECT SNN
  clp: {
    hostedUIUrl: "https://bridge-development-us-east-2.auth.us-east-2.amazoncognito.com/oauth2/",
    hostedUIlogout: "https://bridge-development-us-east-2.auth.us-east-2.amazoncognito.com/logout",
    identityPoolId: "us-east-2:104beccc-bece-4cba-9bdf-aca7787f1582",
    userPoolId: "us-east-2_YxCP9q0Jo",
    userPoolWebClientId: "3l18a8d52u64s486c17h6ubef",
    userPoolAwsLogin: "cognito-idp.us-east-2.amazonaws.com/us-east-2_YxCP9q0Jo",
    cloudfrontCLPURL: 'https://bridge-development.faiplan-dev.com',
    cloudFrontCLPSignInUrl: 'https://bridge-development.faiplan-dev.com/signin',
    cloudFrontCLPSignOutUrl: 'https://bridge-development.faiplan-dev.com/logout',
    cloudFrontCLPExitAppUrl: 'https://bridge-development.faiplan-dev.com/application/product',
  },
  //SNN ROLE
  snnClp: {
    hostedUIUrl: "https://bridge-snn-development-us-east-2.auth.us-east-2.amazoncognito.com/oauth2/",
    hostedUIlogout: "https://bridge-snn-development-us-east-2.auth.us-east-2.amazoncognito.com/logout",
    identityPoolId: "us-east-2:f86e3aa8-d5cd-4888-b2a0-e7cc213dadca",
    userPoolId: "us-east-2_GXaV8vw4N",
    userPoolWebClientId: "3tfbepphte55qpei8gnohkos75",
    userPoolAwsLogin: "cognito-idp.us-east-2.amazonaws.com/us-east-2_GXaV8vw4N",
    cloudfrontCLPURL: 'https://bridge-snn-development.faiplan-dev.com',
    cloudFrontCLPSignInUrl: 'https://bridge-snn-development.faiplan-dev.com/signin',
    cloudFrontCLPSignOutUrl: 'https://bridge-snn-development.faiplan-dev.com/logout',
    cloudFrontCLPExitAppUrl: 'https://bridge-snn-development.faiplan-dev.com/application/product',
  },
  duuCloudFrontSigninUrl: 'https://datauploadutility-development.aetsamd-dev.com/signin',
};