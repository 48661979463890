import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantStrings, linkingNames, ROLE, statusCode, statusCodeString } from 'src/app/shared/helpers/constants';
import { errorResponse } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { UploadService } from 'src/app/uploadfiles/upload.service';

@Component({
  selector: 'app-facilitylinking',
  templateUrl: './facilitylinking.component.html',
  styleUrls: ['./facilitylinking.component.scss']
})
export class FacilitylinkingComponent implements OnInit {

  isSurgeonTrue;
  searchTextOnPlaceHolder: string = 'Search';
  searchiconText: string = 'Search Icon'
  userInfo: any;
  isUnionHospital:string = "1";
  isUnionIc:string = "0";  
  page: any = 1;
  limit: any = statusCode.PageLimit;
  errorInfacilityList: errorResponse;
  facilityListRes: any;
  totalCount: number = 0;
  displayedColumns: string[] = ['isLinked', 'facilityName', 'address', 'city', 'facilityContactLead', 'facilityPhone'];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<any>(false, []);
  selectedData: any;
  group: string;
  icAdmin: boolean;
  removeLinkData: any;
  createLinkData: any;
  linkActive: boolean = false;
  linkInactive: boolean = false;
  searchTextOrg: string = '';
  facilityData: any;
  toggleFlag: boolean = true;
  searchFlag: boolean = false;
  filterOrgDetails: any;
  filterSortArr: any[];
  searchSortArr: any[];
  selectionFlag: boolean = false;
  selectedId: string;
  sortedArray: any[];
  selectedFacilityType: any;
  errUserDetails: any;
  actionActivityDetails: any;
  auditAction: any;
  auditText: string | number;
  auditId: string | number;
  totalRecord: number = 0;
  facilityListResLength: number = 0;
  linkedArray=[];
  unLinkedArray=[];
  toggleClicked: boolean;
  constructor(private sharedState: SharedStateService,
    private uploadService: UploadService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private _liveAnnouncer: LiveAnnouncer,
    private changeDetector: ChangeDetectorRef) {
  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    //To get user actions from json file using shared service
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    if (this.authService.getItem('clpSession')) {
      const idToken = this.authService.getCognitoAccessTokenFromStorage();
      const decodedValue = this.authService.decode(idToken);
      this.group = decodedValue ? decodedValue['cognito:groups'][0] : '';
      this.icAdmin = (this.group.toLowerCase() === ROLE.IC_ADMIN) ? true : false;
    }
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
      this.getFacility(this.userInfo.userFacilityId);
    }
    this.isSurgeonTrue = {
      isDisplaySelectionfacility: false,
      isDisplaySelectionSurgeon: false,
      isDisplaySelectionApplication: false,
      isUploadFailed: false,
      isHelpScreen: false
    }
    this.sharedState.setDashboardStateData(this.isSurgeonTrue);
  }
  /**
  * @description function call on selecting row in table
  * @parameter event, selected listitem
  * @returns component
  */
  dataSelection(event, listItem) {
    this.selectedData = listItem;
    this.selectedFacilityType = listItem.facilityType;
    this.selectedId = listItem.facilityId;
    this.disableButton();
  }
  /**
  * @description function call to enable and disable the buttons
  * @parameter null
  * @returns component
  */
  disableButton(){
    if((this.selectedData.isLinked == true) && (this.userInfo.userFacilityId != this.selectedData.facilityId)) {
      this.linkActive = true;
      this.linkInactive = false;
    } else if((this.selectedData.isLinked == false) && (this.userInfo.userFacilityId != this.selectedData.facilityId)) {
      this.linkInactive = true;
      this.linkActive = false;
    } else {
      this.linkActive = false;
      this.linkInactive = false;
    }
  }

  /**
  * @description function call to get facility list
  * @parameter facilityId
  * @returns component
  */
  public getFacility(facilityId): any {
    this.spinnerService.show();
    let apiParams: HttpParams = new HttpParams();
    apiParams = apiParams.append('linkedFacilityId', facilityId);
    if(this.userInfo && this.userInfo.userRole && (this.userInfo.userRole.toLowerCase() == ROLE.IC_ADMIN)){
      apiParams = apiParams.append('isUnion', this.isUnionIc);
    } else {
      apiParams = apiParams.append('isUnion', this.isUnionHospital);
    }   
    if(this.toggleFlag){
      apiParams = apiParams.append('isPinned', "true");   
    } else {
      apiParams = apiParams.append('isPinned', "false");   
    }
    apiParams = apiParams.append('pageno', this.page);
    apiParams = apiParams.append('limit', this.limit);
    apiParams = apiParams.append('searchstring', this.searchTextOrg);
    this.uploadService.getFacilityLinkingDetails(apiParams).subscribe({
      next: result => {
        this.toggleClicked = false;
        if (result && result.facilityLinkingResponses) {
          this.facilityData = result;
          if (this.page == 1) {
            this.facilityListRes = result.facilityLinkingResponses;
          } else {
            this.facilityListRes = [...this.facilityListRes, ...result.facilityLinkingResponses]
          }
          this.facilityListResLength = this.facilityListRes.length;
          if(this.toggleFlag && !this.icAdmin){
            this.sortLinkedUnlinked();
          } else if(this.icAdmin || !this.toggleFlag){
            this.filterSortArr = [...this.facilityListRes];
            this.sortedArray = this.sortFacilityName()
            this.dataSource.data = this.sortedArray;
          }
          this.totalRecord = this.facilityData.totalRecords;
          this.totalCount = this.facilityListResLength;
          if(this.totalCount != 0 && !this.selectedId) {
            this.selection = new SelectionModel<any>(true, [this.dataSource.data[0]]);
            this.selectedId = this.selection.selected[0]['facilityId'];
            this.selectedData = this.dataSource.data[0];
            this.selectedFacilityType = this.selectedData.facilityType;
            this.disableButton();
          }
        }
        if(this.searchTextOrg != ''){
          this.auditLogActivity(constantStrings.SEARCH);
        }  
        this.spinnerService.hide();
      }, error: err => {
        if(this.searchTextOrg != ''){
          this.auditLogActivity(constantStrings.SEARCH);
        }        
        this.errorInfacilityList = err;
        this.spinnerService.hide();
      }
    });
  }

  /**
  * @description function call to sort linked facility
  * @parameter null
  * @returns component
  */
  public sortLinkedUnlinked() {
    this.linkedArray = [];
    this.unLinkedArray = [];
    this.toggleFlag = true;
    this.searchSortArr = [...this.facilityListRes];
    this.sortIsLinked();
    this.linkedArray.push(...this.unLinkedArray)
    this.dataSource.data = this.linkedArray;
  }
  /**
  * @description function call to sort linked facility
  * @parameter null
  * @returns component
  */
  public sortIsLinked(){
    for(let key of this.searchSortArr){
      if(key.isLinked == true){
        this.linkedArray.push(key);
        this.linkedArray = this.linkedArray.sort((a, b) =>
        a.facilityName.toLowerCase() !== b.facilityName.toLowerCase()
          ? a.facilityName.toLowerCase() < b.facilityName.toLowerCase()
            ? -1
            : 1
          : 0
        );
      }else {
        this.unLinkedArray.push(key);
        this.unLinkedArray = this.unLinkedArray.sort((a, b) =>
        a.facilityName.toLowerCase() !== b.facilityName.toLowerCase()
          ? a.facilityName.toLowerCase() < b.facilityName.toLowerCase()
            ? -1
            : 1
          : 0
        );
      }
    }
  }

  /**
  * @description function call to toggle and sort
  * @parameter toggle event
  * @returns component
  */
  public toggle(event: MatSlideToggleChange, flag) {
    if ((event && event.checked == false) || flag == false) {
      this.toggleFlag = false;
        this.filterSortArr = [...this.facilityListRes];
      this.sortedArray = this.sortFacilityName()
      this.dataSource.data = this.sortedArray;
    }
    if ((event && event.checked == true) || flag == true) {
      this.sortLinkedUnlinked();
    }
    this.toggleClicked = true;
  }
  /**
  * @description function call to sort table based on facility name ascending order
  * @parameter null
  * @returns component
  */
  sortFacilityName(){
    return this.filterSortArr.sort((a, b) =>
    a.facilityName.toLowerCase() !== b.facilityName.toLowerCase()
      ? a.facilityName.toLowerCase() < b.facilityName.toLowerCase()
        ? -1
        : 1
      : 0
    );
  }
  /**
  * @description function call to sort linked facility
  * @parameter null
  * @returns component
  */
  sortDatas(sort: Sort) {
    this.filterSortArr = [...this.facilityListRes];
    const data = this.filterSortArr.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = this.sortTableData(data, sort);
  }
  /**
  * @description function call to sort linked facility
  * @parameter null
  * @returns component
  */
  sortTableData(data, sort){
    return data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case linkingNames.FACILITYNAME: return compare(a.facilityName, b.facilityName, isAsc);
        case linkingNames.ADDRESS: return compare(a.address, b.address, isAsc);
        case linkingNames.CITY: return compare(a.city, b.city, isAsc);
        case linkingNames.FACILITYCONTACTLEAD: return compare(a.facilityContactLead, b.facilityContactLead, isAsc);
        case linkingNames.FACILITYPHONE: return compare(a.facilityPhone, b.facilityPhone, isAsc);
        default: return 0;
      }
    });
  }

  /**
  * @description function call to return list based on search
  * @parameter null
  * @returns component
  */
  public onTextSearch() {
    this.page = 1;
    this.totalCount = 0;
    this.getFacility(this.userInfo.userFacilityId)
  }

  /**
  * (description): when clicked on show more button this function is called
  * (parameter) : null
  * (memberof) : HistoryComponent
  */
  ShowMore() {
    if(this.toggleClicked){
      this.page = 1;
    } else {
      this.page = this.page + 1;
    }
    this.getFacility(this.userInfo.userFacilityId);
  }

  /**
  * @description function call on click of create link button
  * @parameter null
  * @returns component
  */
  public postCreatLinkAPI(): any {
    this.spinnerService.show();
    let apiParams = {
      sourceFacilityId: (this.selectedFacilityType.toLowerCase() == constantStrings.FACILITYIMAGING.toLowerCase()) ? this.selectedId : this.userInfo.userFacilityId,
      targetFacilityId: (this.selectedFacilityType.toLowerCase() == constantStrings.FACILITYIMAGING.toLowerCase()) ? this.userInfo.userFacilityId : this.selectedId,
    }
    this.uploadService.postCreatLinkDetails(apiParams).subscribe({
      next: result => {
        this.createLinkData = result;        
        this.auditLogActivity(constantStrings.LINK);
        this.linkActive = true;
        this.linkInactive = false;
        this.selectionFlag = true;
        this.updateRecords(true);

        this.spinnerService.hide();
      }, error: err => {
        this.auditLogActivity(constantStrings.LINK);
        this.errorInfacilityList = err;
        this.auditLogActivity(statusCodeString.ERROR);
        this.spinnerService.hide();
      }
    });
  }
  /**
  * @description function call on click of remove link button
  * @parameter null
  * @returns component
  */
  public putRemoveLinkAPI(): any {
    this.spinnerService.show();
    let apiParams = {
      sourceFacilityId: (this.selectedFacilityType.toLowerCase() == constantStrings.FACILITYIMAGING.toLowerCase()) ? this.selectedId : this.userInfo.userFacilityId,
      targetFacilityId: (this.selectedFacilityType.toLowerCase() == constantStrings.FACILITYIMAGING.toLowerCase()) ? this.userInfo.userFacilityId : this.selectedId,
    }
    this.uploadService.putRemoveLinkDetails(apiParams).subscribe({
     next: result => {       
        this.removeLinkData = result;
        this.auditLogActivity(constantStrings.DELINK);
        this.linkInactive = true;
        this.linkActive = false;
        this.selectionFlag = true;
        this.updateRecords(false);
        this.spinnerService.hide();
      }, error: err => {
        this.auditLogActivity(constantStrings.DELINK);
        this.errorInfacilityList = err;
        this.spinnerService.hide();
      }
    });
  }
  /**
  * (description): method to update the existing array after click event
  * (parameter) : code
  * (memberof) : Component
  */
  updateRecords(code){
    if(this.toggleFlag && !this.icAdmin){
      for (let key of this.facilityListRes) {
        if (key.facilityId == this.selectedId) {
          key.isLinked = code;
        }
      }
      this.sortLinkedUnlinked();
    } else if(!this.toggleFlag){
      for (let key of this.facilityListRes) {
        if (key.facilityId == this.selectedId) {
          key.isLinked = code;
        }
      }
      this.filterSortArr = [...this.facilityListRes];
      this.sortedArray = this.sortFacilityName()
      this.dataSource.data = this.sortedArray;
    } else if(this.icAdmin){
      this.facilityListRes = this.facilityListRes.filter(
        (obj) => obj.facilityId != this.selectedId
      );
      this.filterSortArr = [...this.facilityListRes];
      this.sortedArray = this.sortFacilityName()
      this.dataSource.data = this.sortedArray;
      this.selection = new SelectionModel<any>(true, [this.dataSource.data[0]]);
      this.selectedId = this.selection.selected[0]['facilityId'];
      this.selectedData = this.dataSource.data[0];
      this.selectedFacilityType = this.selectedData.facilityType;
      this.disableButton();
    }
  }
  /**
  * (description): method to log audit actions
  * (parameter) : action
  * (memberof) : Component
  */
  auditLogActivity(str) {
    if (str === constantStrings.LINK) {
      this.auditText = this.auditAction.UserActions.imagingLinked;
      this.auditId = this.selectedId  +'-'+ this.userInfo.userFacilityId;
    } else if (str === constantStrings.DELINK) {
      this.auditText = this.auditAction.UserActions.imagingDelinked;
      this.auditId = this.selectedId  +'-'+ this.userInfo.userFacilityId;
    } else if (str === constantStrings.SEARCH) {
      this.auditText = this.auditAction.UserActions.searchLog;
      this.auditId = this.searchTextOrg;
    }
    let actionOutcome = {
      "userFacilityId": this.userInfo.userFacilityId,
      "userName": this.userInfo.userName,
      "action" : this.auditText+''+this.auditId,
      "eventOutCome" : this.featureEventOutcome(str)
    }
    this.uploadService.auditLogActivity(actionOutcome).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }
  /**
   * (description): To call function featureActionOutcome which return event outcome for auditLog API
   * (memberof) : Component
  **/
  featureEventOutcome(str){
    if (str === constantStrings.LINK) {
      return this.createLinkData ? 1 : 0;
    } else if (str === constantStrings.DELINK) {
      return this.removeLinkData ? 1 : 0;
    } else if (str === constantStrings.SEARCH) {
      return this.facilityData ? 1 : 0;
    } else {
      return 0
    }
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
