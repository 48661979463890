import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.service';
import { SettingsComponent } from './settings.component';

export const settingsRoutes: Routes = [

  {
    path: 'settings',
    component: SettingsComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: SettingsComponent, canActivate: [AuthGuard] },
    ]
  }
];
export const routing = RouterModule.forRoot(settingsRoutes);
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(settingsRoutes)
  ],
})
export class SettingsRoutingModule { }
