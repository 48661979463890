import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AddSurgeonModal, INITIAL_ADDSURGEON_MODAL_STATE } from 'src/app/shared/models/dialog.model';

@Component({
  selector: 'app-failed-to-retrievedata',
  templateUrl: './failed-to-retrievedata.component.html',
  styleUrls: ['./failed-to-retrievedata.component.scss']
})
export class FailedToRetrievedataComponent implements OnInit {
  public PopUpModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;
  public value = false;
  public showPopUp = false;
  receiveValue($event) {
    this.showPopUp = $event;
  }

  @Output() event = new EventEmitter<boolean>()

  constructor(public router: Router,) { }

  ngOnInit(): void {
  }
  /**
  * (description): method called on click of refresh button Navigate to file dash board page
  * (parameter) : null
  * (memberof) : Component
  */
  Refresh() {  
    this.router.navigate(
      ['/filedashboard']);
    this.event.emit(this.value);
    this.router.navigate(['/filedashboard'])
  }
  /**
  * (description): method called on click of view details button Open details modal in retrieve data error page
  * (parameter) : null
  * (memberof) : Component
  */
  openDetailsModal() { 
    this.showPopUp = true;
    this.PopUpModalUi = {
      display: 'block',
      header: 'Add Surgeon',
      content: '',
      okButton: 'Save',
      cancelButton: 'Cancel',
      submitFunction: () => { },
      closeFunction: () => {
      }
    };
  }

}
