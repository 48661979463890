import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.service';
import { FacilitylinkingComponent } from './facilitylinking/facilitylinking.component';
import { ManagefacilitiesComponent } from './managefacilities.component';

export const managefacilitiesRoutes: Routes = [

  {
    path: 'managefacilities',
    component: ManagefacilitiesComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: ManagefacilitiesComponent, canActivate: [AuthGuard] },
    ]
  },
  { path: 'managefacilities/facilitylinking', component: FacilitylinkingComponent, canActivate: [AuthGuard]  },
];
export const routing = RouterModule.forRoot(managefacilitiesRoutes);
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(managefacilitiesRoutes)
  ],
})

export class ManagefacilitiesRoutingModule { }
