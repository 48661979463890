import * as AuthActions from './auth.actions';
import { AdminUserInfo, ResendCodeInfo } from '../../shared/models/user.model';
import { CognitoUser } from 'amazon-cognito-identity-js';

export interface State {
  adminUser: AdminUserInfo;
  token: string;
  authenticated: boolean;
  cognitouser: CognitoUser;
  resendCodeInfo: ResendCodeInfo;
  confirmError: boolean;
}

const initialState: State = {
  adminUser: undefined,
  token: null,
  authenticated: false,
  cognitouser: null,
  resendCodeInfo: undefined,
  confirmError: undefined
};

export function authReducer(state = initialState, action: AuthActions.AuthActions) {
  switch (action.type) {
    case (AuthActions.CONFIRM_SIGNUP):
      return {
        ...state,
        authenticated: true
      };
    case (AuthActions.SET_SIGNUP_INFO):
      return {
        ...state,
        adminUser: action.payload
      };
    case (AuthActions.SIGNUP):
    case (AuthActions.SIGNIN):
      return {
        ...state,
        cognitouser: action.payload
      };
    case (AuthActions.SET_RESEND_CODE_INFO):
      return {
        ...state,
        resendCodeInfo: action.payload
      };
    case (AuthActions.CONFIRMATION_ERROR):
      return {
        ...state,
        confirmError: action.payload
      };
    default:
      return state;
  }
}