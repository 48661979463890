import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { INITIAL_MODAL_STATE, Modal } from './shared/models/dialog.model';
import { AuthService } from './shared/services/auth.service';
import { SharedStateService } from './shared/services/shared-state.service';
import { StorageService } from './shared/services/storage.service';
import { errorResponse, settingsResponse } from './shared/models/user.model';
import { environment } from 'src/environments/environment';
import { UploadService } from './uploadfiles/upload.service';
import { LoginData } from './shared/models/login.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showSessionTimeOutModal: boolean;
  public sessionTimeOutModel: Modal = INITIAL_MODAL_STATE;
  public sessionTimeOutLocalize: any;
  isInfoPopUpSelected;
  showInfoPopUp: boolean;
  sessionUploadErrorLocalize: any;
  userActions: any;
  idleTime: number = 0;
  errorDetails: errorResponse;
  userInfo: LoginData;
  actionActivityDetails: settingsResponse;
  auditAction: any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private translate: TranslateService,
    private bnIdle: BnNgIdleService,
    private sharedState: SharedStateService,
    private storageService: StorageService,
    private uploadService: UploadService
  ) {
  }
  ngOnInit(): void {
    //Reading Idletime ssmInfo after getting clpsession
    if(this.authService.getItem('clpSession')) this.ssmInfo();
    //Recieving loggedIn user details from stateservice 
    this.sharedState.loginStateData$.subscribe(response => {
      this.userInfo = response; 
    });
    //Recieving auditaction messages from stateservice
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    this.translate.setDefaultLang('en');
    this.translate.get(['sessionTimeOutModal'])
      .subscribe((data) => {
        this.sessionTimeOutLocalize = data.sessionTimeOutModal
      });

    this.translate.get(['uploadErrorModel'])
      .subscribe((data) => {
        this.sessionUploadErrorLocalize = data.uploadErrorModel
      });

    this.translate.get(['UserActions'])
      .subscribe((data) => {
        this.userActions = data;
        this.sharedState.userActionDetails(this.userActions);
      });

    this.sharedState.uploadStateData$.subscribe(response => {
      this.isInfoPopUpSelected = response.isInfoPopUp;
      if (this.isInfoPopUpSelected) {
        this.showInfoPopUp = true;
        this.sessionTimeOutModel = {
          display: 'block',
          header: "",
          content: "",
          okButton: "",
          cancelButton: '',
          submitFunction: () => { this.closeInfoPopupDialog(); },
          closeFunction: () => { this.closeInfoPopupDialog(); }
        };
      }
    })
    this.sharedState.ssmIdleTimeData$.subscribe(response => {
      if (response && response.ssmKey == environment.ssmParams.IDLETIME) {
        this.idleTime = +response.ssmVal * 60;
        if (this.idleTime != 0) {
          this.checkInactivity();
        }
      }
    })
  }

  /**
    * @description to get the SSM parameter values
    * @memberof component
  */
  public ssmInfo() {
    this.authService.getSSMValue(environment.ssmParams.IDLETIME);
  }

  // below function will watch the application idle time, after 15 min it will clear the sesstion and redirect to login page  
  public checkInactivity() {
    this.bnIdle.startWatching(this.idleTime).subscribe((res) => {
      if (res && (!this.router.url.includes('/signin') && !this.router.url.includes('/signup'))) {
        this.auditLogActivity();
      }
    })
  }


  /**
   * @description To close the modal dailog.
   * @returns sets the modal in initial state.
   * @memberof AppComponent
   */
  public closeSessionTimeOutDialog() {
    this.showSessionTimeOutModal = false;
    this.sessionTimeOutModel = INITIAL_MODAL_STATE;
    this.storageService.clearAll();
    this.authService.signOut();
  }

  /**
   * @description To close the Info modal dailog.
   * @returns sets the modal in initial state.
   * @memberof AppComponent
  */
  public closeInfoPopupDialog() {
    this.sessionTimeOutModel = INITIAL_MODAL_STATE;
  }

  /**
   * (description): To call audit log activity function
   * (memberof) : component
  **/
   auditLogActivity(){
    let action = {
      "userFacilityId" : this.userInfo.userFacilityId,
      "userName" : this.userInfo.userName,
      "action" : this.auditAction.UserActions.logoutSuccess,
      "eventOutCome" : 1
    }
    this.uploadService.auditLogActivity(action).subscribe({
      next: result => {
        this.actionActivityDetails = result;
        this.storageService.clearAll();
        this.authService.signOut();
      }, error: err => {
        this.errorDetails = err;
        this.storageService.clearAll();
        this.authService.signOut();
      }
    });
  }
}
