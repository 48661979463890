import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { constantStrings } from 'src/app/shared/helpers/constants';
import { AddSurgeonModal, INITIAL_ADDSURGEON_MODAL_STATE, INITIAL_MODAL_STATE, Modal } from 'src/app/shared/models/dialog.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { UploadService } from '../upload.service';
import { settingsResponse } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-upload-error',
  templateUrl: './upload-error.component.html',
  styleUrls: ['./upload-error.component.scss']
})
export class UploadErrorComponent implements OnInit {

  @Input() modal: Modal = INITIAL_MODAL_STATE;
  public addModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;
  @ViewChild(ImageUploadComponent) FacilityModel;
  public showUploadError: boolean;
  displaySelectionFacility;
  displaySelectionSurgeon;
  displaySelectionApplication;
  isApplicationTrue;
  isSurgeonTrue;
  isUploadFailed=true;

  value;
  skippedFilesDetails: any;
  dicomData: any;
  id: string;
  displayFacility: string;
  displaySurgeon: string;
  displayApplication: string;
  errorMsgDetails: boolean;
  fileSizeError: boolean = false;
  userInfo: any;
  actionActivityDetails: settingsResponse;
  errUserDetails: any;
  auditAction: any;
  skipTags : boolean = false;
  skipMandTags : boolean = false;
  skipInValidDicom : boolean = false;
  receiveValue($event){
    this.showUploadError=$event;
  }
  constructor(
    private translateService: TranslateService,
    public router: Router,
    private sharedState: SharedStateService,
    private authService: AuthService,
    private actRouter: ActivatedRoute,
    private uploadService: UploadService
  ) { 
    this.actRouter.queryParams
    .subscribe(params => {
      this.id = params.batchId;
    });
    this.showUploadError = true;
    translateService.setDefaultLang(constantStrings.defaultLanguage);
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    //Recieving  display selection value from state service 
    this.sharedState.facilityStateData$.subscribe(response=>{
      this.displayFacility=response.displaySelectionfacility;
    })

    this.sharedState.surgeonStateData$.subscribe(response=>{
      this.displaySurgeon = response.displaySelectionSurgeon;
    })

    this.sharedState.applicationStateData$.subscribe(response=>{
      this.displayApplication=response.displaySelectionApplication;
    })

    //Recieving skipped files details from state service
    this.sharedState.dicomStateData$.subscribe(response => {
      this.dicomData = response;
      this.skippedFilesDetails = response.invalidDicom;
    });

    //Recieving loggedIn user details from local storage 
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
    }
    this.getFailedDetails();
  }

  ngOnInit(): void {
  }
  /**
   * (description): to get file upload failed details
   * (memberof) : Component
  **/
  getFailedDetails(){
    let uploadFileStorage = this.authService.getItem('uploadFileStatus');
    if(uploadFileStorage){
    for(let statusFile of uploadFileStorage){
      if(this.id){
        if(statusFile.batchId == this.id){
          this.displaySelectionApplication = statusFile.applicationName;
          this.displaySelectionFacility = statusFile.facilityName;
          this.displaySelectionSurgeon = statusFile.surgeonName;
          if(statusFile.errorReason == constantStrings.FILESIZERR){
            this.fileSizeError = true;
          }else if(statusFile.errorReason == constantStrings.ALLSKIPPED){
            this.errorMsgDetails = true;
            if(statusFile.skipTags){
              this.skipTags = true;
            }else if(statusFile.skipMandTags){
              this.skipMandTags = true;
            }else{
              this.skipInValidDicom  = true;
            }
          }
        }
      }else {
        if(statusFile.batchId == this.dicomData.batchId){          
            this.displaySelectionApplication = this.displayApplication;
            this.displaySelectionFacility = this.displayFacility;
            this.displaySelectionSurgeon = this.displaySurgeon;
            if(statusFile.errorReason == constantStrings.ALLSKIPPED){
              this.errorMsgDetails = true;
              if(statusFile.skipTags){
                this.skipTags = true;
              }else if(statusFile.skipMandTags){
                this.skipMandTags = true;
              }else{
                this.skipInValidDicom  = true;
              }
            }       
          }
      }
    }
    }
    this.authService.setItem('uploadFileStatus', JSON.stringify(uploadFileStorage))
  }
  /**
  * (description): method called to open success modal
  * (parameter) : null
  * (memberof) : Component
  */
  public openSuccessModal(): void {
    this.showUploadError = false;
    this.isUploadFailed=false;
    this.addModalUi = {
      display: 'block',
      header: 'Add Surgeon',
      content: '',
      okButton: 'Save',
      cancelButton: 'Cancel',
      submitFunction: () => { },
      closeFunction: () => {
      }
    };
  }
  /**
   * (description): To open site popup on click of start new upload button
   * (memberof) : Component
  **/
  public openFacilityModal(): void {
    let notification = {
      displayUploadFile: false,
      displaySiteModal: true,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.router.navigate(
      ['/uploadfiles'],
      { queryParams: { id: constantStrings.nextUpload } }
    );
  }
  /**
   * (description): To open selectfiles popup on click of reupload button
   * (memberof) : Component
  **/
  public openSelectUploadModal(): void {
    this.auditLogActivity();
    let notification = {
      displayUploadFile: false,
      displaySiteModal: false,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: true
    }
    this.sharedState.popupCancelData(notification);
    this.router.navigate(
      ['/uploadfiles'],
      { queryParams: { id: constantStrings.reUpload, batchId: this.id } }
    );
  }

  /**
   * (description): To call audit log activity function
   * (memberof) : Component
  **/
  auditLogActivity(){
    let action = {
      "userFacilityId" : this.userInfo.userFacilityId,
      "userName" : this.userInfo.userName,
      "action" : this.auditAction.UserActions.fileReuploaded + this.dicomData.batchId,
      "eventOutCome" : 1
    }
    this.uploadService.auditLogActivity(action).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }

}
