import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ConfirmModal, INITIAL_CONFIRMMODAL_STATE } from '../../models/addnewusermodal';
import { AuthService } from '../../services/auth.service';


@Component({
    selector: 'app-confirmmodal',
    templateUrl: './confirmmodal.component.html',
    styleUrls: ['./confirmmodal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
    @Input() modal: ConfirmModal = INITIAL_CONFIRMMODAL_STATE;
    @Output() submitModal = new EventEmitter();
    @Output() cancelModal = new EventEmitter();
    constructor(private authService: AuthService) {}
    ngOnInit(): void{}

    public hide() {
        this.cancelModal.emit();
    }

    public confirm(): void{
        this.submitModal.emit();
    }
    //Logout the application on click of Back button if session is timedout 
    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
      if(localStorage.getItem('clpSession') == null){
        this.authService.logout();
      }
    }
}
