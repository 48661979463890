import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { constantStrings } from '../../helpers/constants';
import { SharedStateService } from '../../services/shared-state.service';
import { SidemenuService } from '../sidemenu/sidemenu.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sidemenu-colapse',
  templateUrl: './sidemenu-colapse.component.html',
  styleUrls: ['./sidemenu-colapse.component.scss']
})
export class SidemenuColapseComponent implements OnInit {
  infoTabActive=false;
  helpScreen=false;
  isHelpScreenActive=false;
  constructor(
    private translateService: TranslateService,
    private sidemenuService : SidemenuService,
    private sharedState: SharedStateService,
    private spinnerService: NgxSpinnerService
  ) {
    this.translateService.setDefaultLang(constantStrings['defaultLanguage']);
    this.sharedState.uploadStateData$.subscribe(response=>{
      this.infoTabActive=response.isInfoPopUp;
      this.isHelpScreenActive=response.isHelpScreen;
    })
   }

  ngOnInit(): void {
  }

  openInfoModal(){
    let notification= {
      isInfoPopUp:true
    }
    
    this.sharedState.setDashboardStateData(notification);
}

    //On clicking logout icon users logs out from the applicaion
    public userLogout(): void {
      this.spinnerService.show();
      this.sidemenuService.userLogout();
      const keys = ['isError','uploadFileStatus'];
      keys.forEach(key => localStorage.removeItem(key));
    }

    openPdfViewer(){
      let notification= {
        isHelpScreen:true
      }
      this.sharedState.setDashboardStateData(notification);
    }


}
