import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Modal, INITIAL_MODAL_STATE } from 'src/app/shared/models/dialog.model';
import { errorResponse, GetAuditExportResponse, settingsResponse } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { AuditlogService } from '../auditlog.service';

@Component({
  selector: 'app-downloadreadypopup',
  templateUrl: './downloadreadypopup.component.html',
  styleUrls: ['./downloadreadypopup.component.scss']
})
export class DownloadreadypopupComponent implements OnInit {
  @Input() modal: Modal = INITIAL_MODAL_STATE;
  public value : boolean = true;
  @Output() event = new EventEmitter<boolean>();
  userInfo: any;
  errorAuditLog: errorResponse;
  auditAction: any;
  actionActivityDetails: settingsResponse;
  errUserDetails: errorResponse;
  getAuditExportDetails: GetAuditExportResponse;

  constructor(
    private auditlogService: AuditlogService,
    private authService: AuthService,
    private sharedState: SharedStateService
  ) {
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
    }
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });

  }

  ngOnInit(): void {
  }
  /**
  * (description): method call on click of cancel or close button
  * (memberof) : Component
  */
  public onCancel(): void {
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
  }
  /**
  * (description): method call on click of download button
  * (memberof) : Component
  */
  downloadAll() {
    this.auditlogService.getAuditExportAll(this.userInfo.userFacilityId).subscribe({
      next: result => {
        this.getAuditExportDetails = result;
        if(result.logfileurl){
          const a = document.createElement("a");
          a.href = result.logfileurl;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.auditLogActivity();
          this.modal.display = 'none';
        } else {
          this.modal.display = 'none';
          this.event.emit(this.value);
        }
      }, error: err => {
        this.auditLogActivity();
        this.errorAuditLog = err;
      }
    });
  }
  /**
* (description): method call to log audit actions
* (memberof) : Component
*/
  auditLogActivity() {
    let actionOutcome = {
      "userFacilityId": this.userInfo.userFacilityId,
      "userName": this.userInfo.userName,
      "action": this.auditAction.UserActions.auditLogExport,
      "eventOutCome": this.getAuditExportDetails ? 1 : 0
    }
    this.auditlogService.auditLogActivity(actionOutcome).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }
}
