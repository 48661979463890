export const errorMessages = {
    // java
    1000: 'Unable to process the request with the provided input',
    1001: 'Authorization header not provided in the request',
    1002: 'Invalid schema in the body provided',
    1003: 'Invalid URL parameters',
    1004: 'Mandatory fields not provided in JSON payload',
    1016: 'License code is not valid or License code is not valid/Password combination',
    2000: 'This request could not be processed at the server. Please contact administrator.',
    2001: 'Some mandatory data doesnot exists in DB',
    2002: 'Data could not be deleted due to server internal issue',
    2003: 'Data could not be saved due to server internal issue',
    2004: 'Data could not be Edited due to server internal issue',
    2005: 'Data could not be fetched due to server internal issue',
    1010: 'Invalid License code',
    1011: 'License code expired',
    1012: 'License code already in use for another user',
    1013: 'username already exist',
    1014: 'Active Subscrition does not exist',
    1015: 'User has not chosen terminate request option in admin settings',
    1057: 'Fail to activate license',
    1058: 'Failed to register license for user',
    1059: 'Failed to verify user subscription',
    1060: 'Failed to get license information for user',
    1071: 'User not allow to perform the action',
    1032: 'Incorrect procedure time format',
    1033: 'SurgeonId provided in JSON is not present in the system',
    1034: 'Media file path could not be updated for given GPID and MediaId',
    1035: 'surgeonname is mandatory field',
    1037: 'Patient Procedure details not found',
    1038: 'Gpid is mandatory field',
    1039: 'Global annotation file path could not be updated for given annotationid and gpid',
    1040: 'Local annotation file path could not be updated for given annotationid,mediaid and gpid',
    1041: 'Error in delete operation. Records could not be deleted for the given gpid.',
    1051: 'Invalid Authorization header value',
    1030: 'Invalid date format',
    1031: 'Invalid Number format',
    1036: 'pageno is mandatory field',
    1042: 'Given Shortcut name already exist',
    1043: 'Id is mandatory field',
    1045: 'Device Blob file path could not be updated for given blobid and gpid',
    1052: 'Blob name already exists on cloud',
    1053: 'Setup blob information not updated for the given surgeonname and old blobname',
    1054: 'Annotation Name already exists',
    1070: 'License is terminated',
    1072: 'Template Name already exists',
    1073: 'Survey Response not found exception',
    1046: 'Version number already exists for this device type and subtype',
    1056: 'DUR records were not deleted for given durids',
    1055: 'DUR records were not found for given durids',
    1061: 'Groupname already exist',
    1062: 'Tagname for procedure already exist',
    1067: 'Archive/Restore status update failed',
    1066: 'Email and Phonenumber not updated',
    1069: 'Procedure already blocked on cloud for this Gpid',
    1065: 'Surgeon already mapped to different Admin',
    1064: 'Surgeon already mapped to same Admin',
    1063: 'License code/Password combination is not valid',
    1068: 'Notification type not exists in system',
    1074: 'Device type name already exist',
    1075: 'Device subtype name already exist',
    1076: 'Code is invalid',
    // cognito
    InternalErrorException: 'Internal error',
    NotAuthorizedException: 'Username or password is not valid',
    MfaOtpExpired: 'Code expired',
    ResetPwdOtpExpired: 'Code expired',
    ConfirmOtpExpired: 'Code expired',
    VerifyContactOtpExpired: 'Code expired',
    ResourceNotFoundException: 'Resource not found',
    TooManyRequestsException: 'Too many requests',
    UserNotFoundException: 'Invalid Username or Password',
    ResetPwdUsernameInvalid: 'An authentication code will be sent to the registered e-mail/mobile number',
    UserNotConfirmedException: 'User not confirmed',
    InvalidEmailRoleAccessPolicyException: 'Invalid Email',
    InvalidLambdaResponseException: 'Invalid Response',
    InvalidSmsRoleAccessPolicyException: 'Invalid Contact',
    InvalidSmsRoleTrustRelationshipException: 'Invalid Contact',
    UnexpectedLambdaException: 'The system has encountered an error. Please contact S&N customer service.',
    UserLambdaValidationException: 'User validation exception',
    LimitExceededException: 'Attempt limit exceeded, please try after some time',
    CodeDeliveryFailureException: 'Code delivery failure',
    AliasExistsException: 'Username already exists',
    CodeMismatchException: 'Invalid verification code provided, please try again',
    UsernameExistsException: 'User already exists',
    // S3
    AccessDenied: 'Access Denied',
    AccountProblem: 'Account Problem',
    AllAccessDisabled: 'All access disabled',
    AmbiguousGrantByEmailAddress: 'Ambiguous grant by email address',
    AuthorizationHeaderMalformed: 'Authorization header mal formed',
    BadDigest: 'Invalid Content-MD5 checksum',
    BucketAlreadyExists: 'Bucket already exists',
    BucketAlreadyOwnedByYou: 'Bucket already owned by you',
    BucketNotEmpty: 'Bucket not empty',
    CredentialsNotSupported: 'Credentials not supported',
    CrossLocationLoggingProhibited: 'Cross location logging prohibited',
    EntityTooSmall: 'Entity too small',
    EntityTooLarge: 'Entity too large',
    ExpiredToken: 'Expired token',
    IllegalVersioningConfigurationException: 'Illegal versioning configuration',
    IncompleteBody: 'Incomplete body',
    IncorrectNumberOfFilesInPostRequest: 'Incorrect number of files in request',
    InlineDataTooLarge: 'Inline data too large',
    InternalError: 'Internal error',
    InvalidAccessKeyId: 'Invalid access key id',
    InvalidAddressingHeader: 'Invalid address header',
    InvalidArgument: 'Invalid argument',
    InvalidBucketName: 'Invalid bucket name',
    InvalidBucketState: 'Invalid bucket state',
    InvalidDigest: 'Invalid Content-MD5 checksum',
    InvalidEncryptionAlgorithmError: 'Invalid encryption algorithm',
    InvalidLocationConstraint: 'Invalid location constraint',
    InvalidObjectState: 'Invlaid object state',
    InvalidPart: 'Invalid part',
    InvalidPartOrder: 'Invalid part order',
    InvalidPayer: 'Invalid payer',
    InvalidPolicyDocument: 'Invalid policy document',
    InvalidRange: 'Invalid range',
    InvalidRequest: 'Invalid request',
    InvalidSecurity: 'Invalid security',
    InvalidSOAPRequest: 'Invalid request',
    InvalidStorageClass: 'Invalid storage',
    InvalidTargetBucketForLogging: 'Invalid target bucket',
    InvalidToken: 'Invalid token',
    InvalidURI: 'Invalid URI',
    KeyTooLongError: 'Key too long',
    MalformedACLError: 'Mal formed ACL',
    MalformedPOSTRequest: 'Mal formed request',
    MalformedXML: 'Mal formed',
    MaxMessageLengthExceeded: 'Max message length exceeded',
    MaxPostPreDataLengthExceededError: 'Max post data length exceeded',
    MetadataTooLarge: 'Meta data too large',
    MethodNotAllowed: 'Method not allowed',
    MissingAttachment: 'Missing attachment',
    MissingContentLength: 'Missing content',
    MissingRequestBodyError: 'Missing request body',
    MissingSecurityElement: 'Missing security element',
    MissingSecurityHeader: 'Missing security header',
    NoLoggingStatusForKey: 'No logging status for key',
    NoSuchBucket: 'No such bucket',
    NoSuchBucketPolicy: 'No suck bucket policy',
    NoSuchKey: 'No such key',
    NoSuchLifecycleConfiguration: 'No such life cycle configuration',
    NoSuchUpload: 'No such upload',
    NoSuchVersion: 'No such version',
    NotImplemented: 'Not implemented',
    NotSignedUp: 'Not signed up',
    OperationAborted: 'Operation aborted',
    PermanentRedirect: 'Permanent redirect',
    PreconditionFailed: 'Precondition failed',
    Redirect: 'Redirect',
    RestoreAlreadyInProgress: 'Restore already in progress',
    RequestIsNotMultiPartContent: 'Request is not multipart content',
    RequestTimeout: 'Request timeout',
    RequestTimeTooSkewed: 'Request Time too skewed',
    RequestTorrentOfBucketError: 'Request torrent of bucket error',
    SignatureDoesNotMatch: 'Signature does not match',
    ServiceUnavailable: 'Service unavailable',
    SlowDown: 'Slow down',
    TemporaryRedirect: 'Temporary redirect',
    TokenRefreshRequired: 'Token refresh required',
    TooManyBuckets: 'Too many buckets',
    UnexpectedContent: 'Unexpected content',
    UnresolvableGrantByEmailAddress: 'Unresolvable grant by email address',
    UserKeyMustBeSpecified: 'User key must be specified',
    ServerSideEncryptionConfigurationNotFoundError: 'Server side encryption configuraton not found error',
    // lambda invoke
    EC2AccessDeniedException: 'Ec2 access denied exception',
    EC2ThrottledException: 'Ec2 throttled exception',
    EC2UnexpectedException: 'Ec2 unexpected exception',
    ENILimitReachedException: 'ENI limit reached exception',
    InvalidParameterValueException: 'Invalid Parameter value exception',
    InvalidRequestContentException: 'Invalid request content exception',
    InvalidRuntimeException: 'Invalid runtime exception',
    InvalidSecurityGroupIDException: 'Invalid security group id exception',
    InvalidSubnetIDException: 'Invalid subnet id exception',
    InvalidZipFileException: 'Invalid zip file exception',
    KMSAccessDeniedException: 'KMS access denied exception',
    KMSDisabledException: 'KMS disabled exception',
    KMSInvalidStateException: 'KMS invalid state exception',
    KMSNotFoundException: 'KMS not found exception',
    RequestTooLargeException: 'Request too large exception',
    ServiceException: 'Service exception',
    SubnetIPAddressLimitReachedException: 'Subnet IP address limit reached exception',
    UnsupportedMediaTypeException: 'Unsupported media type exception',
    // amplify
    missingConfig: 'Missing configuration',
    invalidParameter: 'Invalid parameter'

}