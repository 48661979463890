import { Action } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AdminUserInfo, ResendCodeInfo } from '../../shared/models/user.model';

export const SIGNUP = 'SIGNUP';
export const SET_SIGNUP_INFO = 'SET_SIGNUP_INFO';
export const CONFIRM_SIGNUP = 'CONFIRM_SIGNUP';
export const SIGNIN = 'SIGNIN';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_RESEND_CODE_INFO = 'SET_RESEND_CODE_INFO';
export const CONFIRMATION_ERROR = 'CONFIRMATION_ERROR';

export class Signup implements Action {
  readonly type = SIGNUP;
  constructor(public payload: CognitoUser) { }
}

export class SetSignupInfo implements Action {
  readonly type = SET_SIGNUP_INFO;
  constructor(public payload: AdminUserInfo) { }
}

export class ConfirmSignup implements Action {
  readonly type = CONFIRM_SIGNUP;
}

export class SignIn implements Action {
  readonly type = SIGNIN;
  constructor(public payload: CognitoUser) { }
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class SetToken implements Action {
  readonly type = SET_TOKEN;

  constructor(public payload: string) { }
}

// to resend the verification code
export class ResendConfirmCode implements Action {
  readonly type = SET_RESEND_CODE_INFO;
  constructor(public payload: ResendCodeInfo) { }
}

export class SendConfirmError implements Action {
  readonly type = CONFIRMATION_ERROR;
  constructor(public payload: boolean) { }
}
export type AuthActions = Signup | SetSignupInfo | ConfirmSignup |
  SignIn | Logout | SetToken | ResendConfirmCode | SendConfirmError;
