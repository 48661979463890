import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.service';
import { ChooseFacilityComponent } from './choose-facility.component';

export const chooseFacilityRoutes: Routes = [
  {
    path: 'choosesite',
    component: ChooseFacilityComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: ChooseFacilityComponent, canActivate: [AuthGuard] },
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(chooseFacilityRoutes)
  ],
})
export class ChooseFacilityRoutingModule { }
