export class DisplaySurgeonData {
    displaySelectionfacility:string
    displaySelectionSurgeon:string
    displaySelectionApplication:string;
    surgeon: any;           //Surgeon object
    displayFacilityId: number;
    displaySurgeonId: number;
    displaySurgeonFName: string;
    displaySurgeonLName: string;
    selectedFacility: any;
    surgeonSelected: any;
    targetSurgeonId: string;
    surgeonEmail: string;
}
