import { Injectable } from '@angular/core';
import { ApiprocedureService } from '../shared/java/apiprocedure.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  file : any;
  batchid: string;
  startTime: number;
  dicomTagSSM = [];
  constructor(
    private apiProcedureService:ApiprocedureService,
    ) { 
    
  }

//Function to get Surgeon API response
public getsurgeonlist(facilityId): Observable<any> {
  return this.apiProcedureService.getSurgeon(facilityId);
}
/**
* (description): to get loggedIn user details
* (parameter) : userName, callback
* (returns) : callback
* (memberof) : UploadService
**/
public getLoggedInDetails(UserName, group): Observable<any> {
  return this.apiProcedureService.getLoggedInDetails(UserName, group);
}

//Function to get Application API response
public getapplicationlist(facilityId,surgeonId): Observable<any> {
  return this.apiProcedureService.getApplication(facilityId,surgeonId);
}

  /**
* (description): to get uloaded file status 
* (parameter) : userName, callback
* (returns) : callback
* (memberof) : UploadService
**/
public getUploadStatusAPI(params): Observable<any> {
  return this.apiProcedureService.getUploadStatusAPI(params);
}


  /**
* (description): to get uloaded file status 
* (parameter) : uploadid, bacthid
* (returns) : callback
* (memberof) : UploadService
**/
public recallUploadAPI(params): Observable<any> {
  return this.apiProcedureService.recallUploadAPI(params);
}


/**
* (description): To create batchId for upload file
* (returns) : callback
* (memberof) : UploadService
**/
public createBatch(params): Observable<any> {
  return this.apiProcedureService.createBatch(params);
}
/**
* (description): To send details of skipped files
* (parameter) : fileName, filePath, batchId
* (returns) : callback
* (memberof) : UploadService
**/
public skippedFile(params): Observable<any> {
  return this.apiProcedureService.skippedFile(params);
}
/**

* (description): To send details of skipped files
* (parameter) : fileName, filePath, batchId
* (returns) : callback
* (memberof) : UploadService
**/

public flattenedFilesAPI(params): Observable<any> {
  return this.apiProcedureService.flattenedFilesAPI(params);
}

/**

* (description): To get filter details
* (parameter) : facilityId
* (returns) : callback
* (memberof) : UploadService
**/

public dashboardHistoryFilterAPI(facilityId): Observable<any> {
  return this.apiProcedureService.dashboardHistoryFilter(facilityId);
}

/**
* (description): To get settings details
* (parameter) : facilityId
* (returns) : callback
* (memberof) : UploadService
**/

public getSettingsDetails(facilityId): Observable<any> {
  return this.apiProcedureService.getSettingsDetails(facilityId);
}

/**
* (description): to put settings details
* (parameter) : params
* (returns) : callback
* (memberof) : UploadService
**/
public putSettingsDetails(params): Observable<any> {
  return this.apiProcedureService.putSettingsDetails(params);
}

/**
* (description): To get facility details
* (parameter) : params
* (returns) : callback
* (memberof) : UploadService
**/
public getFacilityLinkingDetails(params): Observable<any> {
  return this.apiProcedureService.getFacilityLinkingDetails(params);
  }

/**
* (description): To get history details
* (parameter) : params
* (returns) : callback
* (memberof) : UploadService
**/
public historyDetails(params): Observable<any> {
  return this.apiProcedureService.historyDetails(params);
}

/**
* (description): To get view history details
* (parameter) : uploadId
* (returns) : callback
* (memberof) : UploadService
**/
public getViewHistoryDetails(uploadId): Observable<any> {
  return this.apiProcedureService.getViewHistoryDetails(uploadId);
}

/**
* (description): To post the create link details
* (parameter) : sourceFacilityId, targetFacilityId
* (returns) : callback
* (memberof) : UploadService
**/
public postCreatLinkDetails(params): Observable<any> {
  return this.apiProcedureService.postCreatLinkDetails(params);
}

/**
* (description): To put the remove link details
* (parameter) : sourceFacilityId, targetFacilityId
* (returns) : callback
* (memberof) : UploadService
**/
public putRemoveLinkDetails(params): Observable<any> {
  return this.apiProcedureService.putRemoveLinkDetails(params);
}

/**
* (description): Get facilities details
* (parameter) : facilitytype
* (returns) : callback
* (memberof) : UploadService
**/
public chooseFacility(facilityType): Observable<any> {
  return this.apiProcedureService.chooseFacility(facilityType);
}
/**
* (description): Post user activity details
* (parameter) : params
* (returns) : callback
* (memberof) : UploadService
**/
 public auditLogActivity(params): Observable<any> {
  return this.apiProcedureService.auditLogActivity(params);
 }
}

