import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantStrings } from '../shared/helpers/constants';
import { INITIAL_MODAL_STATE, Modal } from '../shared/models/dialog.model';
import { AuthService } from '../shared/services/auth.service';
import { UploadService } from '../uploadfiles/upload.service';

@Component({
  selector: 'app-filedashboardfilter',
  templateUrl: './filedashboardfilter.component.html',
  styleUrls: ['./filedashboardfilter.component.scss']
})
export class FileDashboardFilterComponent implements OnInit {
  /**
    * Class Name: FileDashboardFilterComponent
    * Description: this class is used to filter Surgeon, Sites, Application and Status in filter dashboard screen
    * Author : Brinda Durai
    */

  //Object to store selected filters
  public selectedFilters: any = {
    surgeon: [],
    site: [],
    application: [],
    status: []
  }

  @Input() modal: Modal = INITIAL_MODAL_STATE;
  public showSurgeons: boolean = false;
  public showSites: boolean = false;
  public showStatus: boolean = false;
  public showApplications: boolean = false;
  selectedSurgeons = [];
  selectedSites = [];
  selectedApp = [];
  selectedStatus = [];
  itemSelected: string;
  public today: Date = new Date();
  public maxDate: Object = new Date(this.today);
  startDate: '';
  endDate: '';
  //event emmitter to emit an event and pass the parameters on emit to another component receiving value
  @Output() event = new EventEmitter<any>();
  public showDate: boolean = false;
  public showStartDate: boolean = false;
  public showEndDate: boolean = false;
  selectedStart: Date;
  selectedEnd: Date;
  minDate: Date;
  chosenDateFlag: boolean = false;
  apiError;
  filterDetailsAPI;
  apiApplicationRes = [];
  apiSurgeonRes = [];
  apiSiteRes = [];
  apiStatusRes = [];
  userInfo;

  constructor(public router: Router,
    private uploadService: UploadService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService) { }

  ngOnInit(): void {
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
      this.getFilterDetails(this.userInfo?.userFacilityId);
    }
  }

  //This method is called on choosing dates
  dateChosen(event, key) {
    let eventPath = event.srcElement.className;
    let chosenStartDate = event.srcElement.innerHTML;
    if (this.selectedEnd) this.maxDate = this.selectedEnd
    if ((chosenStartDate >= 1 && chosenStartDate <= 31) &&
      ((eventPath.includes('mat-calendar-body-cell-content mat-focus-indicator mat-calendar-body-selected')) ||
        (eventPath.includes('mat-calendar-body-cell-content mat-focus-indicator mat-calendar-body-today mat-calendar-body-selected')))) {
      this.chosenDateFlag = true;
    } else {
      this.chosenDateFlag = false;
    }
    if (key == constantStrings.STARTDATE && this.selectedStart && this.chosenDateFlag) {
      this.showStartDate = !this.showStartDate;
      this.minDate = this.selectedStart;
    }
    else if (key == constantStrings.ENDDATE && this.selectedEnd && this.chosenDateFlag) {
      this.showEndDate = !this.showEndDate;
    }
  }

  //This method is used to Hide Show the filter buttons
  toggle(id) {
    if (id == constantStrings.surgeonGroup) {
      this.showSurgeons = !this.showSurgeons;
    } else if (id == constantStrings.SITE) {
      this.showSites = !this.showSites;
    } else if (id == constantStrings.APPLICATION) {
      this.showApplications = !this.showApplications;
    } else if (id == constantStrings.STATUS) {
      this.showStatus = !this.showStatus;
    } else if (id == constantStrings.DATE) {
      this.showDate = !this.showDate;
    } else if (id == constantStrings.STARTDATE) {
      this.showStartDate = !this.showStartDate;
      this.showEndDate = false;
    } else if (id == constantStrings.ENDDATE) {
      this.showEndDate = !this.showEndDate;
      this.showStartDate = false;
    }
  }

  //This method is used to Select and deselect filters buttons
  onSelect(selectItem, id) {
    this.itemSelected = selectItem;
    if (id == constantStrings.surgeonGroup) {
      for (let surgeon of this.apiSurgeonRes) {
        if (this.itemSelected == surgeon.surgeonId) {
          if (this.selectedSurgeons.includes(surgeon.surgeonId)) {
            surgeon.isSelected = false;
            this.selectedSurgeons.splice(this.selectedSurgeons.indexOf(surgeon.surgeonId), 1);
          }
          else {
            surgeon.isSelected = true;
            this.selectedSurgeons.push(selectItem);
          }
        }
      }
    } else if (id == constantStrings.SITE) {
      for (let site of this.apiSiteRes) {
        if (this.itemSelected == site.facilityId) {
          if (this.selectedSites.includes(site.facilityId)) {
            site.isSelected = false;
            this.selectedSites.splice(this.selectedSites.indexOf(site.facilityId), 1);
          }
          else {
            site.isSelected = true;
            this.selectedSites.push(selectItem);
          }
        }
      }
    } else if (id == constantStrings.APPLICATION) {
      for (let app of this.apiApplicationRes) {
        if (this.itemSelected == app.applicationId) {
          if (this.selectedApp.includes(app.applicationId)) {
            app.isSelected = false;
            this.selectedApp.splice(this.selectedApp.indexOf(app.applicationId), 1);
          }
          else {
            app.isSelected = true;
            this.selectedApp.push(selectItem);
          }
        }
      }
    } else if (id == constantStrings.STATUS) {
      for (let status of this.apiStatusRes) {
        if (this.itemSelected == status) {
          if (this.selectedStatus.includes(status)) {
            status.isSelected = false;
            this.selectedStatus.splice(this.selectedStatus.indexOf(status), 1);
          }
          else {
            status.isSelected = true;
            this.selectedStatus.push(selectItem);
          }
        }
      }
    } else if ((id == constantStrings.STARTDATE || id == constantStrings.ENDDATE) && selectItem == null) {
      this.selectedStart = selectItem;
      this.selectedEnd = selectItem;
      this.minDate = selectItem;
      this.maxDate = this.today;
    }
    //storing selected values of filters
    this.selectedFilters.surgeon = this.selectedSurgeons;
    this.selectedFilters.site = this.selectedSites;
    this.selectedFilters.application = this.selectedApp;
    this.selectedFilters.status = this.selectedStatus;
    this.selectedFilters.startDate = this.selectedStart;
    this.selectedFilters.endDate = this.selectedEnd;
  }

  //This method is used to pass filter values to filedashboard component on submit and close the modal
  public onSubmit(): void {
    //hiding all displayed values of filters
    this.showSurgeons = false;
    this.showApplications = false;
    this.showSites = false;
    this.showStatus = false;
    this.showDate = false;
    this.showStartDate = false;
    this.showEndDate = false;
    //sending selected values for filters
    this.event.emit(this.selectedFilters);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';      //close modal
  }

  //Function to get surgeon API response
  public getFilterDetails(facilityId): any {
    this.spinnerService.show();
    this.uploadService.dashboardHistoryFilterAPI(facilityId).subscribe({
      next:result => {
        this.filterDetailsAPI = result;
        if(this.filterDetailsAPI.surgeonFilterResponse){
          for (let surgeon of this.filterDetailsAPI.surgeonFilterResponse) {
            let surgeonDetails = {
              surgeonName: surgeon.surgeonFirstName[0] + " " + surgeon.surgeonLastName,
              surgeonId: surgeon.surgeonId,
              isSelected: false
            }
            this.apiSurgeonRes.push(surgeonDetails)
          }
        }
        if(this.filterDetailsAPI.facilityFilterResponse){
          for (let site of this.filterDetailsAPI.facilityFilterResponse) {
            let siteDetails = {
              facilityName: site.targetFacilityName,
              facilityId: site.targetFacilityId,
              isSelected: false
            }
            this.apiSiteRes.push(siteDetails)
          }
        }
        if(this.filterDetailsAPI.applicationFilterResponse){
          for (let app of this.filterDetailsAPI.applicationFilterResponse) {
            let appDetails = {
              applicationName: app.applicationName,
              applicationId: app.applicationId,
              isSelected: false
            }
            this.apiApplicationRes.push(appDetails)
          }
        }
        if(this.filterDetailsAPI.statusFilterResponse){
          for (let status of this.filterDetailsAPI.statusFilterResponse) {
            let statusDetails = {
              status: status,
              isSelected: false
            }
            this.apiStatusRes.push(statusDetails)
          }
        }
        this.spinnerService.hide();
      }, error: err => {
        this.apiError = err;
        this.spinnerService.hide();
      }
    });
  }
  /**
  * (description): method called to clear the selected filters
  * (parameter) : null
  * (memberof) : Component
  */
  clearAll() {
    this.onSelect(null, constantStrings.STARTDATE);
    this.onSelect(null, constantStrings.ENDDATE);
    this.clearArray(this.apiSurgeonRes);
    this.clearArray(this.apiSiteRes);
    this.clearArray(this.apiApplicationRes);
    this.clearArray(this.apiStatusRes);
    this.clearDate();
    this.selectedFilters = {
      surgeon: [],
      site: [],
      application: [],
      status: []
    }
    this.selectedSurgeons = [];
    this.selectedSites = [];
    this.selectedApp = [];
    this.selectedStatus = [];
    this.selectedStart = null;
    this.selectedEnd = null;
  }
  /**
  * (description): method called to clear the selected filters
  * (parameter) : 
  * (memberof) : Component
  */
  clearDate() {
    this.selectedStart = null;
    this.selectedEnd = null;
    this.selectedFilters.startDate = this.selectedStart;
    this.selectedFilters.endDate = this.selectedEnd;
    this.minDate = null;
    this.maxDate = new Date();
  }
  /**
  * (description): method called to clear the selected filters
  * (parameter) : Selected Array
  * (memberof) : Component
  */
  clearArray(Arr) {
    let dataArray = Arr;
    dataArray.map((data, i) => {
      dataArray[i].isSelected = false;
    });
  }
}
