export class LoginData {
    position: string;
    userName: string;
    group: string;
    details: string;
    preferredUserName: string;
    userRole: string;
    createdBy: string;
    updatedBy: string;
    userStatus: string;
    createdDate: string;
    updatedDate: string;
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    profilePic: string;
    userFacilityAddress: string;
    userFacilityCity: string;
    userFacilityContactLead: string;
    userFacilityEmail: string;
    userFacilityId: number;
    userFacilityName: string;
    userFacilityPhone: string;
    userFacilityState: string;
    userFacilityZipCode: string;
}

export class LoggedInUserDetails {
    userName: string;
    userRole: string;
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userFacilityAddress: string;
    userFacilityCity: string;
    userFacilityContactLead: string;
    userFacilityEmail: string;
    userFacilityId: number;
    userFacilityName: string;
    userFacilityPhone: string;
    userFacilityState: string;
    userFacilityZipCode: string;
}
