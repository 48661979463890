export class DisplayFacilityData {
    displaySelectionfacility:string
    displaySelectionSurgeon:string
    displaySelectionApplication:string;
    displayFacilityId: number;
    displaySurgeonId: number;
    displaySurgeonFName: string;
    displaySurgeonLName: string;
    displayFacilityType: string;
    selectedFacility: any;
    isFacilitySelectionChanged;
}
