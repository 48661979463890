import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { INITIAL_MODAL_STATE, Modal } from 'src/app/shared/models/dialog.model';
import { AuditActionData, DashboardResponseData, errorResponse, LoggedUserDetails, settingsResponse } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { FileDashboardService } from '../filedashboard.service';
import { UploadService } from 'src/app/uploadfiles/upload.service';

@Component({
  selector: 'app-confirm-recall-pop-up',
  templateUrl: './confirm-recall-pop-up.component.html',
  styleUrls: ['./confirm-recall-pop-up.component.scss']
})
export class ConfirmRecallPopUpComponent implements OnInit, OnChanges {

  @Input() modal: Modal = INITIAL_MODAL_STATE;
  @Input() recallObj: DashboardResponseData;
  public value : boolean = false;
  @Output() event = new EventEmitter<boolean>();
  selectedDashboardItem: any;
  errRecallDetails: any;
  recallDetailsRes: any;
  isRecalled: boolean;
  actionActivityDetails: settingsResponse;
  userInfo: LoggedUserDetails;
  errUserDetails: errorResponse;
  auditAction: AuditActionData;
  displayFacility: string;
  displaySurgeon: string;
  displayApplication: string;

  constructor(public router: Router,
    private sharedState: SharedStateService,
    private fileDashboardService: FileDashboardService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private uploadService:UploadService) {
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    //Recieving loggedIn user details from local storage 
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
    }
  }

  ngOnChanges() {
    //Recieving recall object value
    this.displayFacility = this.recallObj.targetFacilityName;
    this.displaySurgeon = this.recallObj.surgeonId;
    this.displayApplication = this.recallObj.applicationName;
  }

  ngOnInit(): void {
  }

  /**
   * (description): function call to close pop up or cancel recall
   * (memberof) : ConfirmRecallPopUpComponent
   */
  public onCancel(): void {
    this.value = false;
    let recalled = {
      isRecalled: false,
      uploadId: ''
    }
    this.sharedState.displayDashboardError(recalled);
    this.event.emit(this.value);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
  }

  /**
   * (description): function call to confirm recall
   * (memberof) : ConfirmRecallPopUpComponent
   */
  public onConfirm(): void {
    this.value = true;
    const body = {
      "batchId": this.recallObj.batchId,
      "uploadId": this.recallObj.uploadId
    }
    //call recall api function
    this.recallUploadAPI(body);
    let recalled = {
      isRecalled: true,
      uploadId: this.recallObj.uploadId
    }
    this.sharedState.displayDashboardError(recalled);
    this.event.emit(this.value);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
  }

  /**
   * (description): API function call to recall uploaded file
   * (parameter) : Uploadid, batchId
   * (memberof) : ConfirmRecallPopUpComponent
   */
  public recallUploadAPI(params): any {
    this.spinnerService.show();
    this.fileDashboardService.recallUploadAPI(params).subscribe({
      next: result => {
        this.recallDetailsRes = result;
        this.auditLogActivity();
        this.spinnerService.hide();
      }, error: err => {
        this.errRecallDetails = err;
        this.auditLogActivity();
        this.spinnerService.hide();
      }
    });
  }

  /**
   * (description): To call audit log activity function
   * (memberof) : Component
  **/
  auditLogActivity(){
    if(this.userInfo && this.recallObj){
      let action = {
        "userFacilityId" : this.userInfo.userFacilityId,
        "userName" : this.userInfo.userName,
        "action" : this.auditAction.UserActions.uploadRecalled + this.recallObj.batchId + " " + 
        this.displaySurgeon + " " + this.displayApplication + " " + this.displayFacility,
        "eventOutCome" : this.recallDetailsRes ? 1 : 0
      }
      this.uploadService.auditLogActivity(action).subscribe({
        next: result => {
          this.actionActivityDetails = result;
        }, error: err => {
          this.errUserDetails = err;
        }
      });
    }
  }

}