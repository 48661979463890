import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { INITIAL_MODAL_STATE, Modal } from '../shared/models/dialog.model';
import { DOCUMENT } from '@angular/common'; 
import { constantStrings } from '../shared/helpers/constants';

@Component({
  selector: 'app-applicationnotlistedpopup',
  templateUrl: './applicationnotlistedpopup.component.html',
  styleUrls: ['./applicationnotlistedpopup.component.scss']
})
export class ApplicationNotListedPopUpComponent implements OnInit {

  @Input() modal: Modal = INITIAL_MODAL_STATE;

  @Input() applicationMsg: string;

  public value = false;
  @Output() event = new EventEmitter<boolean>();
  appFlag: boolean;
  constructor(public router: Router,@Inject(DOCUMENT) document: Document) {
   }

  ngOnInit(): void {
    this.appFlag = this.applicationMsg.includes(constantStrings.APPLICATION);
  }

  ngAfterViewInit() {
    this.appFlag = this.applicationMsg.includes(constantStrings.APPLICATION);
    document.getElementById('i-dont-see-modal').style.display='none';
    this.modal.display = 'none';
  }
  /**
  * (description): method called on click of close button
  * (parameter) : null
  * (memberof) : Component
  */
  public onCancel(): void {
    this.event.emit(this.value);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
}

}