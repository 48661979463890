import { Injectable } from '@angular/core';
import { ApiprocedureService } from '../shared/java/apiprocedure.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageFacilityService {

  constructor(private apiProcedureService: ApiprocedureService,) { }

  public getFacilities(facilityType): Observable<any> {
    return this.apiProcedureService.getFacilities(facilityType);
  }
  public getFacilityLinkingDetails(params): Observable<any> {
    return this.apiProcedureService.getFacilityLinkingDetails(params);
  }
  public postFacilityLinkingDetails(params): Observable<any> {
    return this.apiProcedureService.postFacilityLinkingDetails(params);
  }
  public putFacilityLinkingDetails(params): Observable<any> {
    return this.apiProcedureService.putFacilityLinkingDetails(params);
  }
  public putActivationStatus(params): Observable<any> {
    return this.apiProcedureService.putActivationStatus(params);
  }
  public auditLogActivity(params): Observable<any> {
    return this.apiProcedureService.auditLogActivity(params);
  }

}
