import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { constantStrings } from '../shared/helpers/constants';
import { AddSurgeonModal, INITIAL_ADDSURGEON_MODAL_STATE } from '../shared/models/dialog.model';
import { AuthService } from '../shared/services/auth.service';
import { SharedStateService } from '../shared/services/shared-state.service';
import { ImageUploadComponent } from './image-upload/image-upload.component';

@Component({
  selector: 'app-uploadfiles',
  templateUrl: './uploadfiles.component.html',
  styleUrls: ['./uploadfiles.component.scss']
})
export class UploadfilesComponent implements OnInit {
  public addModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;
  @ViewChild(ImageUploadComponent) FacilityModel;
  public showUploadModal: boolean;
   
  public flag: boolean;
  public flagError=false;
  public id: any;
  public selectModal: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;
  showSelect: boolean;
  displaySelectionSurgeon: string;
  displaySelectionApplication: string;
  displaySelectionFacility: string;
  displayFacility: string;
  displaySurgeon: string;
  displayApplication: string;
  batchId: string;
  constructor(private router: Router, private actRouter: ActivatedRoute,
    private sharedState: SharedStateService,private authService: AuthService) { 
    this.showUploadModal = true;
    this.sharedState.popupCancelStateData$.subscribe(response=>{
      if(response?.displayUploadFile || response.displaySiteModal){
        this.showUploadModal= response.displayUploadFile && !response.displaySiteModal;
      } else if(response.displaySelectFilesModal == true){
        this.showSelect = true;
      } else if(response.displayUploadFile == true){
        this.showUploadModal = true;
      }
    });
    this.actRouter.queryParams
      .subscribe(params => {
        this.id = params.id;
        if(this.id == constantStrings.NEXT_UPLOAD_ID) //Check if ID received is "nextUpload"
      {
        this.flag= false;
      }
      if(this.id==constantStrings.reUpload){
        this.batchId = params.batchId;
        this.flagError=true;
        this.sharedState.surgeonStateData$.subscribe(response => {
          this.displaySurgeon = response.displaySelectionSurgeon;
        })
    
        this.sharedState.applicationStateData$.subscribe(response => {
          this.displayApplication = response.displaySelectionApplication;
        })

        this.sharedState.facilityStateData$.subscribe(response => {
          this.displayFacility = response.displaySelectionfacility;
        })
        this.getFailedDetails();
        this.selectModal = {
          display: 'block',
          header: 'Add Surgeon',
          content: '',
          okButton: 'Save',
          cancelButton: 'Cancel',
          submitFunction: () => { },
          closeFunction: () => {
          }
        };
      } else {
        this.flagError = false;
      }
      if(this.id == constantStrings.NEW_UPLOAD_ID) //Check if ID received is "newUpload"
      {
        this.flag= false;
      }
      });

  }

  ngOnInit(): void {
    let notification= {
      isHelpScreen:false
    }
    
    this.sharedState.setDashboardStateData(notification);
  }
  /**
  * (description): method called to get failed details
  * (parameter) : null
  * (memberof) : Component
  */
  getFailedDetails(){
    let uploadFileStorage = this.authService.getItem('uploadFileStatus');
    if(uploadFileStorage){
    for(let statusFile of uploadFileStorage){
      if(this.batchId){
        if(statusFile.batchId == this.batchId){
          this.displaySelectionApplication = statusFile.applicationName;
          this.displaySelectionFacility = statusFile.facilityName;
          this.displaySelectionSurgeon = statusFile.surgeonName;
        }
      } else {
          this.displaySelectionApplication = this.displayApplication;
          this.displaySelectionFacility = this.displayFacility;
          this.displaySelectionSurgeon = this.displaySurgeon;    
      }
    }
    }
    this.authService.setItem('uploadFileStatus', JSON.stringify(uploadFileStorage))
  }

  ngAfterViewInit(): void {
    //Check if Id is newUpload or nextUplaod
    if((this.id == constantStrings.NEXT_UPLOAD_ID || this.id == constantStrings.NEW_UPLOAD_ID) && this.FacilityModel){ 
      this.flag= false;
      this.uploadFile(); //calling uploadFile function
    }
  }
  /**
  * (description): method called on click of upload button
  * (parameter) : null
  * (memberof) : Component
  */
  public uploadFile(): void {
    let oldFacilityId= {
      displayFacilityId: null,
    }
    this.sharedState.facilitySelectionData(oldFacilityId);
    let surgeonObject= {
      surgeonSelected: null,
    }
    this.sharedState.surgeonObjectData(surgeonObject);
    let application= {
      selectedApplicationId: null,
    }
    this.sharedState.applicationIdData(application);
    this.showUploadModal = false;
    let notification = {
      displayUploadFile: false,
      displaySiteModal: true,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.addModalUi = {
      display: 'block',
      header: 'Add Surgeon',
      content: '',
      okButton: 'Save',
      cancelButton: 'Cancel',
      submitFunction: () => { },
      closeFunction: () => {
      }
    };
  }
}