import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { constantStrings } from '../../helpers/constants';
import { SharedStateService } from '../../services/shared-state.service';

@Component({
  selector: 'app-side-menu-notifications',
  templateUrl: './side-menu-notifications.component.html',
  styleUrls: ['./side-menu-notifications.component.scss']
})
export class SideMenuNotificationsComponent implements OnInit {
  public exitappsTabActive = false;
  public currentUrl = '';

  constructor(private translateService: TranslateService, private router: Router,
    private sharedState: SharedStateService) {
      this.translateService.setDefaultLang(constantStrings['defaultLanguage']);
      
    //Navigating based on the options selected
    if (this.router) {
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.currentUrl = event.url;
            this.exitappsTabActive = this.currentUrl.includes('/exitapps') || false;
          }
        });
      }
    }

  ngOnInit(): void {
  }

}
