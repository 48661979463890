import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpLoaderFactory, SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuditlogComponent } from './auditlog.component';
import { AuditlogRoutingModule } from './auditlog-routing.module';
import { MaterialprimeModule } from '../material.module';
import { AuditlogfilterComponent } from './auditlogfilter/auditlogfilter.component';
import { CompilationpopupComponent } from './compilationpopup/compilationpopup.component';
import { DownloadreadypopupComponent } from './downloadreadypopup/downloadreadypopup.component';
import { ExpirationpopupComponent } from './expirationpopup/expirationpopup.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [AuditlogComponent, AuditlogfilterComponent, CompilationpopupComponent, DownloadreadypopupComponent,ExpirationpopupComponent],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    MaterialprimeModule,
    NgxSpinnerModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    AuditlogRoutingModule
  ],
  providers: [DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuditlogModule { }
