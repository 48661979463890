import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { StoreModule } from '@ngrx/store';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticateRoutingModule } from './authenticate/authenticate-routing.module';
import { AuthenticateModule } from './authenticate/authenticate.module';
import { HttpLoaderFactory, SharedModule } from './shared/shared.module';
import { reducers } from './store/reducers/app.reducers';
import { NgxLowerCaseDirectiveModule } from 'ngx-lower-case-directive';
import { DefaultGuard } from './shared/guards/default-guard.service';
import { HistoryRoutingModule } from './history/history-routing.module';
import { HistoryModule } from './history/history.module';
import { SettingsModule } from './settings/settings.module';
import { SettingsRoutingModule } from './settings/settings-routing.module';
import { ExitappsRoutingModule } from './exitapps/exitapps-routing.module';
import { UploadfilesModule } from './uploadfiles/uploadfiles.module';
import { UploadfilesRoutingModule } from './uploadfiles/uploadfiles-routing.module';
import { AuditlogModule } from './auditlog/auditlog.module';
import { AuditlogRoutingModule } from './auditlog/auditlog-routing.module';
import { FiledashboardModule } from './filedashboard/filedashboard.module';
import { FiledashboardRoutingModule } from './filedashboard/filedashboard-routing.module';
import { ManagefacilitiesModule } from './managefacilities/managefacilities.module';
import { ManagefacilitiesRoutingModule } from './managefacilities/managefacilities-routing.module';
import { ExitappsModule } from './exitapps/exitapps.module';
import { MaterialprimeModule } from './material.module';
import { MatNativeDateModule } from '@angular/material/core';
import { AuthGuard } from './shared/guards/auth.service';
import { HelpScreenRoutingModule } from './shared/components/help-screen/help-screen-routing.module';
import { UploadStatusRoutingModule } from './uploadstatus/uploadstatus-routing.module';
import { UploadStatusModule } from './uploadstatus/uploadstatus.module';
import { ChooseFacilityModule } from './choose-facility/choose-facility.module';
import { ChooseFacilityRoutingModule } from './choose-facility/choose-facility-routing.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    AuthenticateRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AuthenticateModule,
    NgxIntlTelInputModule,
    HistoryModule,
    HistoryRoutingModule,
    SettingsModule,
    SettingsRoutingModule,
    ExitappsModule,
    ExitappsRoutingModule,
    UploadStatusModule,
    UploadStatusRoutingModule,
    UploadfilesModule,
    UploadfilesRoutingModule,
    AuditlogModule,
    AuditlogRoutingModule,
    FiledashboardModule,
    FiledashboardRoutingModule,
    ManagefacilitiesModule,
    ManagefacilitiesRoutingModule,
    HelpScreenRoutingModule,
    ChooseFacilityModule,
    ChooseFacilityRoutingModule,
    TranslateModule,
    NgxLowerCaseDirectiveModule,
    CommonModule,
    StoreModule.forRoot(reducers),
    MaterialprimeModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DefaultGuard, AuthGuard],
  bootstrap: [AppComponent],

})
export class AppModule { }
