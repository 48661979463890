import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Modal, INITIAL_MODAL_STATE } from 'src/app/shared/models/dialog.model';

@Component({
  selector: 'app-expirationpopup',
  templateUrl: './expirationpopup.component.html',
  styleUrls: ['./expirationpopup.component.scss']
})
export class ExpirationpopupComponent implements OnInit {
  @Input() modal: Modal = INITIAL_MODAL_STATE;
  public value = false;
  @Output() event = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  public onCancel(): void {
    this.event.emit(this.value);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
  }

}
