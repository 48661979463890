import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiprocedureService } from '../shared/java/apiprocedure.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuditlogService {

  constructor(private http: HttpClient,
    private apiProcedureService: ApiprocedureService,) { }

  /**
  * (description): To fetch the Auditlog 
  * (returns) : this will call the user list api to post the filedashboard
  * (memberof) : AuditLogService
  **/
  public postFetchAuditLogsService(params): Observable<any> {
    return this.apiProcedureService.postFetchAuditLogsService(params);
  }

  /**
  * (description): To export audit log service
  * (memberof) : service
  **/
  public postExportAuditLogsService(params): Observable<any> {
    return this.apiProcedureService.postExportAuditLogsService(params);
  }

  /**
  * (description): To get filter details
  * (memberof) : service
  **/
  public auditLogFilterAPI(facilityId): Observable<any> {
    return this.apiProcedureService.auditLogFilterApi(facilityId);
  }

  /**
  * (description): To log user actions
  * (memberof) : service
  **/
   public auditLogActivity(params): Observable<any> {
    return this.apiProcedureService.auditLogActivity(params);
  }

  /**
  * (description): To get audit log export all  details
  * (memberof) : service
  **/
  public getAuditExportAll(userFacilityId): Observable<any> {
    return this.apiProcedureService.getAuditExportAll(userFacilityId);
  }

  /**
  * (description): To post audit log export all  details
  * (memberof) : service
  **/
  public postAuditExportAll(params): Observable<any> {
    return this.apiProcedureService.postAuditExportAll(params);
  }

}
