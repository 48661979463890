import { Injectable } from '@angular/core';
import { ApiprocedureService } from '../../java/apiprocedure.service';
import { AuthService } from '../../services/auth.service';
import { UploadService } from 'src/app/uploadfiles/upload.service';
import { errorResponse, settingsResponse } from '../../models/user.model';
import { SharedStateService } from '../../services/shared-state.service';

@Injectable({
  providedIn: 'root'
})
export class SidemenuService {
  userInfo: any;
  errUserDetails: errorResponse;
  actionActivityDetails: settingsResponse;
  auditAction: any;

  constructor(
    private Service: ApiprocedureService,
    private authService: AuthService,
    private uploadService: UploadService,
    private sharedState: SharedStateService
  ) {
      //To get user actions from json file
      this.sharedState.userActionStateData$.subscribe(response => {
        this.auditAction = response;
      });
      //Recieving loggedIn user details from local storage 
      if (this.authService.getItem('userDetails')) {
        this.userInfo = this.authService.getItem('userDetails');
      }
   }


  /**
     * @description To post the imaging centre name
     * @param {*} callback
     * @memberof SidemenuService
     * @returns this will call the imaging centre name service
     */
  public getICDetails(callback): any {
    this.Service.getICDetails()
      .subscribe(
        (response) => {
          return callback(null, response);
        },
        (error) => {
          return callback(error, null);
        }
      );
  }

  /**
   * (description): signout
   * (parameter): callback
   * (returns) : Navigates to signin view
   * (memberof) : TopheaderComponent
   */
  public userLogout(): void {
    this.auditLogActivity();
  }

  /**
   * (description): To call audit log activity function
   * (memberof) : component
  **/
   auditLogActivity(){
    let action = {
      "userFacilityId" : this.userInfo.userFacilityId,
      "userName" : this.userInfo.userName,
      "action" : this.auditAction.UserActions.logoutSuccess,
      "eventOutCome" : 1
    }
    this.uploadService.auditLogActivity(action).subscribe({
      next: result => {
        this.actionActivityDetails = result;
        this.authService.signOut();
      }, error: err => {
        this.errUserDetails = err;
        this.authService.signOut();
      }
    });
  }

}