import { Component, OnInit } from '@angular/core';
import { InfoAlertService } from '../../services/infoalert.service';

@Component({
  selector: 'app-infoalert',
  templateUrl: './infoalert.component.html'
})
export class InfoAlertComponent implements OnInit {
  message: any;
  public setTime: any = 3000;
  successMessage: boolean = false;

  constructor(private alertService: InfoAlertService) { }

  ngOnInit(): void{
    this.alertService.getMessageInfo().subscribe(message => { 
      this.message = message;      
    });
  }


public removeAlert(){
  this.alertService.remove('');
}
}
