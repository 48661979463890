import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class DefaultGuard implements CanActivate {

  private state: Observable<any>;
  private confirmRegisterError: boolean;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  /**
   * Default guard to restrict the navigate of Auth pages when user is already logged in
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns
   * @memberof DefaultGuard
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const cognitoIdToken = this.authService.getCognitoIdTokenFromStorage();

    if (this.authService.isAuthenticated()) {
      if (!!cognitoIdToken) {
        const decodedIdToken = this.authService.decode(cognitoIdToken);
        if (!!decodedIdToken) {
          this.router.navigate(['/filedashboard']);
        }
        return false;
      }
    }

    return true;
  }
}
