import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchsurgeon'
})
export class SearchsurgeonPipe implements PipeTransform {

  transform(value: any[], args?: string, key1?: string, key2?: string): any[] {
    if (!value) return null;
    if (!args) return value;
    if (args.trim() === '') return null;

    args = args.trim().toLowerCase();

    // a*b: search for items that start with 'a' and end with 'b'
    if (args.includes('*')) {
      const str = args.split('*')[0].trim();
      const strL = args.split('*')[1].trim();
      return value.filter(
        (res: any) =>
          res[key1].toLowerCase().startsWith(str) && res[key2].toLowerCase().endsWith(strL)
      );
    }
  
    // a?b: search for items that start with 'a' and end with 'b'
    if (args.includes('?')) {
      const strF = args.split('?')[0].trim();
      const strL = args.split('?')[1].trim();
      return value.filter(
        (res: any) =>
          res[key1].toLowerCase().startsWith(strF) && res[key2].toLowerCase().endsWith(strL)
      );
    }

    // Check whether the search text includes in the surgeon list
    return value.filter((res: any) =>
      (res[key1] + ' ' + res[key2]).toLowerCase().includes(args)
    );
  }
}