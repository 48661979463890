import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-help-screen',
  templateUrl: './help-screen.component.html',
  styleUrls: ['./help-screen.component.scss']
})
export class HelpScreenComponent implements OnInit {
  helpCenterPDF: string;
  constructor(private translate: TranslateService) {
      this.translate.get(['HelpCenterFile'])
      .subscribe((data) => {
        this.helpCenterPDF = data.HelpCenterFile;
      });
   }

  ngOnInit(): void {
  }
  

  }

