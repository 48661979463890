import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.service';
import { ExitappsComponent } from './exitapps.component';

export const exitappsRoutes: Routes = [

  {
    path: 'exitapps',
    component: ExitappsComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: ExitappsComponent, canActivate: [AuthGuard] },
    ]
  }
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(exitappsRoutes)
  ],
})
export class ExitappsRoutingModule { }
