import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.service';
import { AuditlogComponent } from './auditlog.component';

export const auditlogRoutes: Routes = [

  {
    path: 'auditlog',
    component: AuditlogComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: AuditlogComponent, canActivate: [AuthGuard] },
    ]
  }
];
export const routing = RouterModule.forRoot(auditlogRoutes);
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(auditlogRoutes)
  ],
})
export class AuditlogRoutingModule { }
