import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileDashboardData, FileDashboardRecall } from '../models/fileDashboard-data.model';
import { DicomData } from '../models/dicom.model';
import { DisplayApplicationData } from '../models/displayApplication-data.model';
import { DisplayFacilityData } from '../models/displayfacility-data.model';
import { DisplaySurgeonData } from '../models/displaySurgeon-data.model';
import { UploadErrorData } from '../models/displayUploadError-data.model';
import { FileDetailsData } from '../models/fileDetails-data.model';
import { LoginData } from '../models/login.model';
import { UploadData } from '../models/upload-data.model';
import { AuditActionData, HistoryData, LoggedUserDetails, PopupCancelData, SnnFacilityData } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
/**
  * Class Name: SharedStateService
  * Description: This state service is used to share local datas from one component to other component ....
  * Author : Brinda Durai
**/
export class SharedStateService {
  uploadState = new UploadData();
  uploadStateSubject = new BehaviorSubject(this.uploadState)
  uploadStateData$: Observable<UploadData>;
  facilityState = new DisplayFacilityData();
  facilityStateSubject = new BehaviorSubject(this.facilityState)
  facilityStateData$: Observable<DisplayFacilityData>;
  surgeonState = new DisplaySurgeonData();
  surgeonStateSubject = new BehaviorSubject(this.surgeonState)
  surgeonStateData$: Observable<DisplaySurgeonData>;
  applicationState = new DisplayApplicationData();
  applicationStateSubject = new BehaviorSubject(this.applicationState)
  applicationStateData$: Observable<DisplayApplicationData>;
  uploadFailedState =  new UploadErrorData();
  uploadFailedStateSubject = new BehaviorSubject(this.uploadFailedState)
  uploadFailedStateData$: Observable<UploadErrorData>;
  loginState = new LoginData();
  loginStateSubject = new BehaviorSubject(this.loginState)
  loginStateData$: Observable<LoginData>;
  userDetails: LoggedUserDetails;
  errUserDetails: string;
  role: string;
  imageurl: string;
  surgeonObjectState = new DisplaySurgeonData();
  surgeonObjectStateSubject = new BehaviorSubject(this.surgeonObjectState)
  surgeonObjectStateData$: Observable<DisplaySurgeonData>;
  applicationIdState = new DisplayApplicationData();
  applicationIdStateSubject = new BehaviorSubject(this.applicationIdState)
  applicationIdStateData$: Observable<DisplayApplicationData>;
  dicomState = new DicomData();
  dicomStateSubject = new BehaviorSubject(this.dicomState)
  dicomStateData$: Observable<DicomData>;
  uploadedFileState = new FileDetailsData();
  uploadedFileStateSubject = new BehaviorSubject(this.uploadedFileState)
  uploadedFileStateData$: Observable<FileDetailsData>;
  fileDashboardState = new FileDashboardData();
  fileDashboardStateSubject = new BehaviorSubject(this.fileDashboardState)
  fileDashboardStateData$: Observable<FileDashboardData>;
  fileDashboardRecall = new FileDashboardRecall();
  fileDashboardRecallSubject=new BehaviorSubject(this.fileDashboardRecall)
  fileDashboardRecallData$: Observable<FileDashboardRecall>;
  popupCancelState = new PopupCancelData();
  popupCancelStateSubject = new BehaviorSubject(this.popupCancelState)
  popupCancelStateData$: Observable<PopupCancelData>;
  historyDetailsState = new HistoryData();
  historyDetailsStateSubject = new BehaviorSubject(this.historyDetailsState)
  historyDetailsStateData$: Observable<HistoryData>;
  snnFacilityState = new SnnFacilityData();
  snnFacilityStateSubject = new BehaviorSubject(this.snnFacilityState)
  snnFacilityStateData$: Observable<SnnFacilityData>;
  userActionState = new AuditActionData();
  userActionStateSubject = new BehaviorSubject(this.userActionState)
  userActionStateData$: Observable<any>;  
  auditLogMessageSubject = new BehaviorSubject(this.userActionState)
  auditLogMessageData$: Observable<any>;
  uploadProgressSubject = new BehaviorSubject<number>(0);
  uploadProgressData$: Observable<any>;
  ssmIdleTimeSubject = new BehaviorSubject<string>('');
  ssmIdleTimeData$: Observable<any>;
  constructor(
  ) {     
    this.uploadStateData$ = this.uploadStateSubject.asObservable();
    this.facilityStateData$=this.facilityStateSubject.asObservable();
    this.surgeonStateData$=this.surgeonStateSubject.asObservable();
    this.applicationStateData$=this.applicationStateSubject.asObservable();
    this.uploadFailedStateData$=this.uploadFailedStateSubject.asObservable();
    this.loginStateData$ = this.loginStateSubject.asObservable();
    this.surgeonObjectStateData$=this.surgeonObjectStateSubject.asObservable();
    this.applicationIdStateData$=this.applicationIdStateSubject.asObservable();
    this.dicomStateData$ = this.dicomStateSubject.asObservable();
    this.uploadedFileStateData$ = this.uploadedFileStateSubject.asObservable();
    this.fileDashboardStateData$ = this.fileDashboardStateSubject.asObservable();
    this.popupCancelStateData$ = this.popupCancelStateSubject.asObservable();
    this.historyDetailsStateData$ = this.historyDetailsStateSubject.asObservable();
    this.snnFacilityStateData$ = this.snnFacilityStateSubject.asObservable();
    this.fileDashboardRecallData$ = this.fileDashboardRecallSubject.asObservable();
    this.userActionStateData$ = this.userActionStateSubject.asObservable();
    this.auditLogMessageData$ = this.auditLogMessageSubject.asObservable();
    this.uploadProgressData$ = this.uploadProgressSubject.asObservable();
    this.ssmIdleTimeData$ = this.ssmIdleTimeSubject.asObservable();
  }

  setDashboardStateData(data: any){
    this.uploadStateSubject.next(data);
  }

  facilitySelectionData(data:any){
    this.facilityStateSubject.next(data);
  }

  surgeonSelectionData(data:any){
    this.surgeonStateSubject.next(data);
  }

  applicationSelectionData(data:any){
    this.applicationStateSubject.next(data);
  }

  uploadFailedData(data:any){
    this.uploadStateSubject.next(data);
  }

  setLoginStateData(data: any){
    this.loginStateSubject.next(data);
  }

  surgeonObjectData(data:any){
    this.surgeonObjectStateSubject.next(data);
  }

  applicationIdData(data:any){
    this.applicationIdStateSubject.next(data);
  }
  
  setDicomStateData(data: any){
    this.dicomStateSubject.next(data);
  }

  uploadedFileDetails(data:any){
    this.uploadedFileStateSubject.next(data);
  }

  displayDashboardError(data: any){
    this.fileDashboardStateSubject.next(data);
  }

  displayDashboardRecall(data:any){
    this.fileDashboardRecallSubject.next(data);
  }

  popupCancelData(data: any){
    this.popupCancelStateSubject.next(data);
  }

  historyDetails(data: any){
    this.historyDetailsStateSubject.next(data);
  }

  snnFacilityDetails(data: any){
    this.snnFacilityStateSubject.next(data);
  }

  userActionDetails(data: any){
    this.userActionStateSubject.next(data);
  }

  auditLogDetails(data: any){
    this.auditLogMessageSubject.next(data);
  }
  uploadProgressPer(data: any){
    this.uploadProgressSubject.next(data);
  }
  ssmIdleTimeVal(data: any){
    this.ssmIdleTimeSubject.next(data);
  }

}
